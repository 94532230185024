import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./updateappearance.css";

const UpdateAppearance = ({ initialInfo, onSave, onClose }) => {
  const [height, setHeight] = useState(initialInfo.height || "");
  const [weight, setWeight] = useState(initialInfo.weight || "");
  const [hairColor, setHairColor] = useState(initialInfo.hairColor || "");
  const [eyeColor, setEyeColor] = useState(initialInfo.eyeColor || "");

  useEffect(() => {
    setHeight(initialInfo.height || "");
    setWeight(initialInfo.weight || "");
    setHairColor(initialInfo.hairColor || "");
    setEyeColor(initialInfo.eyeColor || "");
  }, [initialInfo]);

  const handleSave = () => {
    if (typeof onSave === "function") {
      onSave({ height, weight, hairColor, eyeColor });
    } else {
      console.error("onSave is not a function");
    }
    onClose();
  };

  return (
    <div className="update-appearance-popup-overlay">
      <div className="update-appearance-popup-content">
        <h2 className="update-appearance-popup-title">Edit Appearance</h2>
        <form className="update-appearance-form">
          <div className="update-appearance-form-group">
            <label htmlFor="height">Height</label>
            <input
              id="height"
              type="text"
              value={height}
              onChange={(e) => setHeight(e.target.value)}
              placeholder="Enter height"
              className="update-appearance-input"
            />
          </div>
          <div className="update-appearance-form-group">
            <label htmlFor="weight">Weight</label>
            <input
              id="weight"
              type="text"
              value={weight}
              onChange={(e) => setWeight(e.target.value)}
              placeholder="Enter weight"
              className="update-appearance-input"
            />
          </div>
          <div className="update-appearance-form-group">
            <label htmlFor="hairColor">Hair Color</label>
            <input
              id="hairColor"
              type="text"
              value={hairColor}
              onChange={(e) => setHairColor(e.target.value)}
              placeholder="Enter hair color"
              className="update-appearance-input"
            />
          </div>
          <div className="update-appearance-form-group">
            <label htmlFor="eyeColor">Eye Color</label>
            <input
              id="eyeColor"
              type="text"
              value={eyeColor}
              onChange={(e) => setEyeColor(e.target.value)}
              placeholder="Enter eye color"
              className="update-appearance-input"
            />
          </div>
        </form>
        <div className="update-appearance-popup-footer">
          <button className="update-appearance-save-button" onClick={handleSave}>
            Save
          </button>
          <button className="update-appearance-cancel-button" onClick={onClose}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

UpdateAppearance.propTypes = {
  initialInfo: PropTypes.shape({
    height: PropTypes.string,
    weight: PropTypes.string,
    hairColor: PropTypes.string,
    eyeColor: PropTypes.string,
  }),
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default UpdateAppearance;
