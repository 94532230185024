import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faStar } from "@fortawesome/free-solid-svg-icons";
import EditNamePopup from "../../EditPopup/Editnamepopup";
import { updateFirstName, updateLastName } from "../../../api/axios"; // Import your API functions

const ProfileHeader = ({ userDetails }) => {
  const registerResult = userDetails?.registerResult || {};
  
  // State to manage the first and last names
  const [firstName, setFirstName] = useState(registerResult.firstName || "First Name");
  const [lastName, setLastName] = useState(registerResult.lastName || "Last Name");
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const handleEditClick = () => {
    setIsPopupOpen(true);
  };

  const handlePopupClose = () => {
    setIsPopupOpen(false);
  };

  const handleSave = async (newFirstName, newLastName) => {
    try {
      await updateFirstName(registerResult.id, newFirstName);
      await updateLastName(registerResult.id, newLastName);
      
      // Update the state to reflect the changes
      setFirstName(newFirstName);
      setLastName(newLastName);
    } catch (error) {
      console.error("Failed to update names:", error);
    }
  };

  return (
    <div className="log-profile-header">
      <span className="log-profile-user-name">{firstName}</span>
      <span className="log-profile-user-name">{lastName}</span>
      <FontAwesomeIcon
        icon={faEdit}
        className="log-profile-edit-icon"
        onClick={handleEditClick}
      />
      <div>
        <FontAwesomeIcon icon={faStar} className="star" />
        <span className="profile-score">0 / 10,000</span>
      </div>

      <EditNamePopup
        isOpen={isPopupOpen}
        onClose={handlePopupClose}
        currentFirstName={firstName}
        currentLastName={lastName}
        onSave={handleSave}
      />
    </div>
  );
};

export default ProfileHeader;
