import React from "react";
import "./actors.css";
import Modal from "@mui/material/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const LoginAlert = ({ closeAlert, isLoginAlertOpen, handleClickLogin }) => {
  return (
    <Modal open={isLoginAlertOpen} onClose={closeAlert}>
      <div className="login-alert-overlay">
        <div className="login-alert-content">
          <div className="login-close-button" onClick={closeAlert}>
            <FontAwesomeIcon icon={faTimes} />{" "}
          </div>
          <h3 className="login-alert-h">Log in to your account.</h3>

          <p>Only active casting directors can contact actor.</p>
          <button className="login-button" onClick={handleClickLogin}>
            Log In
          </button>

          <a href="/register" className="create-account-link">
            Create an account
          </a>
        </div>
      </div>
    </Modal>
  );
};

export default LoginAlert;
