import React, { useContext } from "react";
import "./sidebar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faUser,
  faPlus,
  faCog,
  faQuestionCircle,
  faSignOutAlt,
  faBullhorn,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";

const Sidebar = () => {
  const navigate = useNavigate();
  const { logout } = useContext(AuthContext);

  const handleLogOut = () => {
    logout();
    navigate("/");
  };

  const handleClickProfile = () => {
    navigate("/profile");
  };

  return (
    <div className="sidebar">
      <div className="sidebar-top">
        <div className="sidebar-icon">
          <FontAwesomeIcon icon={faEnvelope} />
        </div>
        <div className="sidebar-icon">
          <FontAwesomeIcon icon={faBullhorn} />
        </div>
      </div>
      <div className="sidebar-bottom">
        <div className="sidebar-icon">
          <FontAwesomeIcon icon={faUser} onClick={handleClickProfile} />
        </div>
        <div className="sidebar-icon">
          <FontAwesomeIcon icon={faPlus} />
        </div>
        <Link to="/setting">
          <div className="sidebar-icon">
            <FontAwesomeIcon icon={faCog} />
          </div>
        </Link>
        <div className="sidebar-icon">
          <FontAwesomeIcon icon={faQuestionCircle} />
        </div>
        <div className="sidebar-icon">
          <FontAwesomeIcon icon={faSignOutAlt} onClick={handleLogOut} />
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
