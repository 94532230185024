import React, { useState, useEffect, useContext } from "react";
import "./castingCall.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import SearchFilter from "./SearchFilter";
import Pagination from "@mui/material/Pagination";
import { useNavigate } from "react-router-dom";
import {
  getApprovedCastingCalls,
  getCastingCallsForCreator,
} from "../../api/axios";
import nprogress from "nprogress";
import "nprogress/nprogress.css";
import { AuthContext } from "../../context/AuthContext";
import { Spinner } from "react-bootstrap";

const CastingCallList = () => {
  const navigate = useNavigate();
  const { isAuthenticated, role } = useContext(AuthContext);
  const [searchInputs, setSearchInputs] = useState({
    name: "",
    country: "",
    zip: "",
    radius: "",
    age: "",
    gender: "",
    category: "",
  });
  const [isFiltered, setIsFiltered] = useState(false);
  const [page, setPage] = useState(1);
  const itemsPerPage = 15;
  const [pages, setPages] = useState(1);

  const [castingCallList, setCastingCallList] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);

  //smooth scroll for page top
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  //change state for show reset filter text
  useEffect(() => {
    setIsFiltered(Object.values(searchInputs).some((input) => input !== ""));
  }, [searchInputs]);

  useEffect(() => {
    setFilteredData(castingCallList);
  }, [castingCallList]);

  //pagination change
  const handleChange = (event, value) => {
    setPage(value);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  //fetch casting calls for logged director or producer
  const GetCastingCallsForCreator = async () => {
    nprogress.start();
    try {
      const res = await getCastingCallsForCreator();
      if (res.success) {
        setCastingCallList(res.castings);
      }
    } catch (error) {
      console.log("error fetching casting calls", error);
    } finally {
      nprogress.done();
      setLoading(false);
    }
  };

  //fetch approved casting calls
  const GetApprovedCastingCalls = async () => {
    nprogress.start();
    try {
      const res = await getApprovedCastingCalls();
      if (res.success) {
        let castings = res.castings;
        if (isAuthenticated && role === "crew") {
          castings = castings.filter(
            (casting) => casting.callerJobTitle === "producer" //In here filter casting calls for crew. crew can only apply producers casting calls.
          );
        }
        setCastingCallList(castings);
      }
    } catch (error) {
      console.log("error fetching casting calls", error);
    } finally {
      nprogress.done();
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      if (role === "director" || role === "producer") {
        GetCastingCallsForCreator(role);
      } else {
        GetApprovedCastingCalls();
      }
    } else {
      GetApprovedCastingCalls();
    }
  }, [isAuthenticated, role]);

  //search function
  const handleSearch = () => {
    let filtered = castingCallList;
    
    if (searchInputs.name) {
      filtered = filtered.filter((item) =>
        item.shortDesc.toLowerCase().includes(searchInputs.name.toLowerCase())
      );
    }
    if (searchInputs.country) {
      filtered = filtered.filter((item) =>
        item.country.toLowerCase().includes(searchInputs.country.toLowerCase())
      );
    }
    if (searchInputs.zip) {
      filtered = filtered.filter((item) =>
        item.zipCode.toLowerCase().includes(searchInputs.zip.toLowerCase())
      );
    }
    if (searchInputs.category) {
      filtered = filtered.filter((item) =>
        item.category.toLowerCase().includes(searchInputs.category.toLowerCase())
      );
    }

    setFilteredData(filtered);
    setPage(1);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    const pageCount = Math.ceil(filteredData.length / itemsPerPage);
    setPages(pageCount || 1);
  }, [filteredData]);

  //reset all filters
  const handleResetFilters = () => {
    setSearchInputs({
      name: "",
      country: "",
      zip: "",
      radius: "",
      age: "",
      gender: "",
      category: "",
    });
    setFilteredData(castingCallList);
    setPage(1);
  };

  //pagination
  const totalRoleCount = filteredData?.reduce(
    (acc, castingCall) => acc + castingCall.roles?.length,
    0
  );

  const paginatedData = filteredData?.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  );

  //casting call view click
  const handleViewCastingCall = (id) => {
    navigate(`/castingcalls/view/${id}`);
  };

  //change casting call status color
  const getBackgroundColor = (status) => {
    if (status === "decline") {
      return "red";
    }
    if (status === "approved") {
      return "#019E4B";
    }
    if (status === "pending") {
      return "#FFD700";
    }
  };

  if (loading) {
    return (
      <div className="spinner-page">
        <Spinner className="spinner" />
      </div>
    );
  }
  return (
    <div className="castingCall-list-full-wrapper">
      <div className="castingCall-list-conainer">
        <div className="castingCall-clear-filter">
          <div>
            <span className="all-role-count">{totalRoleCount} roles found</span>
            <span className="cast-cole-count">
              in {filteredData.length} active casting calls
            </span>
          </div>
          {isFiltered && (
            <div onClick={handleResetFilters} className="filter-clear">
              Reset filters
            </div>
          )}
        </div>
        <div className="seperate-line"></div>
        <div className="castingCall-grid">
          <div className="castingCall-list">
            {paginatedData?.map((item) => (
              <div className="castingCall-card-wrapper" key={item.id}>
                <div
                  className="castingCall-card"
                  onClick={() => handleViewCastingCall(item.id)}
                >
                  <h5 className="castingCall-main-title">{item.title}</h5>
                  <div className="home-castcall-title">
                    <div className="title-left">
                      <span>
                        <FontAwesomeIcon icon={faMapMarkerAlt} />
                        <span style={{ marginLeft: "5px" }}>
                          {item.zipCode}
                        </span>
                      </span>
                      <span>Expires on {item.expirationDate}</span>
                    </div>
                    {item.amount && (
                      <span className="title-right">
                        ${item.amount}/{item.period}
                      </span>
                    )}
                  </div>
                  <div className="castcall-description">{item.shortDesc}</div>
                  <div className="castcall-categories">
                    <span className="castcall-category">{item.category}</span>
                  </div>
                  <div className="castingCalle-roleCount">
                    <span>{item.roles?.length}</span>
                    <span>
                      {item.roleCount === 1
                        ? "role available"
                        : "roles available"}
                    </span>
                  </div>
                  {(role === "director" || role === "producer") && (
                    <div
                      className="castingCall-adminStatus"
                      style={{
                        backgroundColor: getBackgroundColor(item.adminStatus),
                      }}
                    >
                      {item.adminStatus}
                    </div>
                  )}
                </div>
              </div>
            ))}
            <div className="pagination">
              <Pagination
                count={pages}
                page={page}
                onChange={handleChange}
                boundaryCount={1}
                sx={{
                  "& .MuiPaginationItem-root": {
                    color: "#e54447",
                    "&:hover": {
                      backgroundColor: "rgba(229, 68, 71, 0.1)",
                    },
                  },
                  "& .MuiPaginationItem-root.Mui-selected": {
                    backgroundColor: "#e54447",
                    color: "#fff",
                    "&:hover": {
                      backgroundColor: "#d43d3f",
                    },
                  },
                  "& .MuiPaginationItem-ellipsis": {
                    color: "#e54447",
                    "&:hover": {
                      backgroundColor: "transparent",
                    },
                  },
                }}
              />
            </div>
          </div>
          <SearchFilter
            searchInputs={searchInputs}
            setSearchInputs={setSearchInputs}
            handleSearch={handleSearch}
          />
        </div>
      </div>
    </div>
  );
};

export default CastingCallList;
