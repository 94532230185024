import React, { useState } from "react";
import "./actors.css";
import LoginAlert from "./LoginAlert";
import ActorModal from "./Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import LoginPopup from "../auth/LoginPopup";

const ActorCard = ({ actor }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoginAlertOpen, setIsLoginAlertOpen] = useState(false);
  const [isLoginOpen, setIsLoginOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const closeLoginAlert = () => setIsLoginAlertOpen(false);

  const handleContactActor = () => {
    closeModal();
    setIsLoginAlertOpen(true);
  };

  const handleClickLogin = () => {
    setIsLoginOpen(true);
    closeLoginAlert();
  };

  return (
    <div>
      <div className="actor-card" onClick={openModal}>
        <div className="image-container">
          <img src={actor.profile.imageURL} alt=" " className="profile-pic" />
          <div className="actor-info">
            <h2 className="actor-name">{actor.user.firstName}</h2>
            <p className="actor-rating">
              <span className="actor-star">
                <FontAwesomeIcon icon={faStar} />
              </span>
              {/* want to add rate value to db */}
              <span className="rating-number">8795</span>
            </p>
          </div>
        </div>
      </div>
      <ActorModal
        actor={actor}
        closeModal={closeModal}
        contactActor={handleContactActor}
        isModalOpen={isModalOpen}
      />

      <LoginAlert
        isLoginAlertOpen={isLoginAlertOpen}
        closeAlert={closeLoginAlert}
        handleClickLogin={handleClickLogin}
      />

      <LoginPopup isLoginOpen={isLoginOpen} setIsLoginOpen={setIsLoginOpen} />
    </div>
  );
};

export default ActorCard;
