import React, { useState, useEffect, useContext } from "react";
import "./actor.css";
import ProfileHeader from "./ProfileHeader";
import ProfileSidebar from "./ProfileSidebar";
import ProfileTabs from "./ProfileTabs";
import ProfileInfo from "./ProfileInfo";
import VideoPage from "../../photopage/VideoPage";
import {
  getUserDetails,
  getDirectorDetails,
  getProducerDetails,
  getCrewDetails,
} from "../../../api/axios";
import nprogress from "nprogress";
import "nprogress/nprogress.css";
import { Spinner } from "react-bootstrap";
import { AuthContext } from "../../../context/AuthContext";

const ActorProfile1 = () => {
  const { role, token, userId } = useContext(AuthContext); // userId comes from AuthContext
  const [activeTab, setActiveTab] = useState("info");
  const [userDetails, setUserDetails] = useState(null);
  const [loading, setLoading] = useState(true);

  //auto scroll to page top
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  // Get logged user details using role and userID
  const GetUserDetails = async (id, role) => {
    try {
      nprogress.start();
      let data;
      switch (role) {
        case "actor":
          data = await getUserDetails(id);
          break;
        case "director":
          data = await getDirectorDetails(id);
          break;
        case "producer":
          data = await getProducerDetails(id);
          break;
        case "crew":
          data = await getCrewDetails(id);
          break;
        default:
          console.error("Unknown role:", role);
          return;
      }

      if (data.success) {
        setUserDetails(data.user);
      }
    } catch (error) {
      console.log("Error fetching user data", error);
    } finally {
      nprogress.done();
      setLoading(false);
    }
  };

  useEffect(() => {
    if (token && userId && role) {
      GetUserDetails(userId, role);
    }
  }, [token, userId, role]);

  if (loading) {
    return (
      <div className="spinner-page">
        <Spinner className="spinner" />
      </div>
    );
  }
  if (!userDetails) {
    return <div className="loading-spinner">No user data available</div>;
  }
  return (
    <div className="log-profile-full-wrapper">
      <div className="log-profile-container">
        <ProfileSidebar
          userDetails={userDetails}
          GetUserDetails={GetUserDetails}
          userId={userId}
          
        />
        <div className="log-profile-right">
          <ProfileHeader
            userDetails={userDetails}
            GetUserDetails={GetUserDetails}
          />
          <ProfileTabs activeTab={activeTab} handleTabClick={handleTabClick} />
          <div className="log-profile-render-div">
            {activeTab === "info" ? (
              <ProfileInfo
                userDetails={userDetails}
                GetUserDetails={GetUserDetails}
                userId={userId} // Pass userId to ProfileInfo
                role={role}
              />
            ) : (
              <VideoPage
                userDetails={userDetails}
                GetUserDetails={GetUserDetails}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActorProfile1;
