import React, { useEffect } from "react";
import "./castingDirector.css";
import DirectorImage from "../../assets/director.webp";
import { useNavigate } from "react-router-dom";

const Top = () => {
  const navigate = useNavigate();

  const handleRegisterClick = () => {
    navigate("/register");
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <div className="director-top-full-wrapper">
      <div className="director-top-conainer">
        <div className="directorTop-smallImage">
          <img
            className="director-top-small-image"
            src={DirectorImage}
            alt=""
          />
        </div>
        <div className="directorTop-left">
          <span className="main-title">
            Discover the Future Stars – Crafting Legacies of Talent!
          </span>
          <span className="director-subColor-title">
            Unleash the potential of the next generation of stars with our
            comprehensive casting platform tailored specifically for children.
            Whether you're in need of a baby for a commercial, a tween for a TV
            show, or a teenager for a feature film, our platform connects you
            with exceptional young talent across the United States and Canada.
          </span>
          <div className="homeTop-buttons">
            <button className="homeMiddle2" onClick={handleRegisterClick}>
              Post Your Casting Call
            </button>
          </div>
        </div>
        <div className="directorTop-right">
          <img className="director-top-image" src={DirectorImage} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Top;
