import React, { useState, useEffect } from "react";
import { getUserDetails, updateUserImage } from "../../api/axios";
import "./Addpopup.css";
import { jwtDecode } from "jwt-decode";

const HeadshotUpload = ({ imgUrl, GetUserDetails ,userId}) => {
  const [imageURL, setImageURL] = useState(
    "https://talent.kidscasting.com/assets/placeholders/circle-male-close_up.svg"
  );
  const token = localStorage.getItem("token");
  const decodedToken = jwtDecode(token);
  const UserId = decodedToken.userId;
  // const userId = 50;

  useEffect(() => {
    const fetchUserImage = async () => {
      try {
        const userData = await getUserDetails(userId);
        const userImageURL = userData.user.result.imageURL;
        setImageURL(
          userImageURL ||
            "https://talent.kidscasting.com/assets/placeholders/circle-male-close_up.svg"
        ); // Use default image if userImageURL is null
      } catch (error) {
        console.error("Error fetching user image:", error);
      }
    };

    fetchUserImage();
  }, [userId]);

  const handleHeadshotUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("imageURL", file);

      try {
        await updateUserImage(userId, formData);
        const updatedUserData = await getUserDetails(userId);
        const updatedImageURL = updatedUserData.user.result.imageURL;
        setImageURL(updatedImageURL);
      } catch (error) {
        console.error("Error uploading headshot:", error);
      }
    }
  };

  return (
    <div className="headshot-upload-wrapper">
      <div className="log-profile-topic">Headshot</div>
      <div className="log-profile-headshot">
        <img src={imgUrl || imageURL} alt="Headshot" className="headshot-img" />
        <input
          type="file"
          accept="image/*"
          onChange={handleHeadshotUpload}
          style={{ display: "none" }}
          id="headshot-upload"
        />
        <label htmlFor="headshot-upload" className="headshot-upload-button">
          Upload New Image
        </label>
      </div>
    </div>
  );
};

export default HeadshotUpload;
