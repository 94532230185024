import React from "react";
import ProfileLayout from "../../components/layout/ProfileLayout";
import Settings from "./Setting";

const SettingPage = () => {
  return (
    <ProfileLayout>
      <div>
        <Settings />
      </div>
    </ProfileLayout>
  );
};

export default SettingPage;
