import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./editpersonal.css";

const EditPersonalPopup = ({ initialInfo, onSave, onClose }) => {
  // Initialize state with fallback values in case initialInfo is undefined or null
  const [age, setAge] = useState(initialInfo.age || "");
  const [location, setLocation] = useState(initialInfo.location || "");
  const [ethnicity, setEthnicity] = useState(initialInfo.ethnicity || "");

  useEffect(() => {
    if (initialInfo) {
      setAge(initialInfo.age || "");
      setLocation(initialInfo.location || "");
      setEthnicity(initialInfo.ethnicity || "");
    }
  }, [initialInfo]);

  const handleSave = () => {
    if (typeof onSave === "function") {
      onSave({ age, location, ethnicity });
    } else {
      console.error("onSave is not a function");
    }
    onClose();
  };

  return (
    <div className="edit-personal-popup-overlay">
      <div className="edit-personal-popup-content">
        <h2 className="edit-personal-popup-title">Edit Personal Information</h2>
        <form className="edit-personal-form">
          <div className="edit-personal-form-group">
            <label htmlFor="age">Age</label>
            <input
              id="age"
              type="text"
              value={age}
              onChange={(e) => setAge(e.target.value)}
              placeholder="Enter age"
              className="edit-personal-input"
            />
          </div>
          <div className="edit-personal-form-group">
            <label htmlFor="location">Location</label>
            <input
              id="location"
              type="text"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
              placeholder="Enter location"
              className="edit-personal-input"
            />
          </div>
          <div className="edit-personal-form-group">
            <label htmlFor="ethnicity">Ethnicity</label>
            <input
              id="ethnicity"
              type="text"
              value={ethnicity}
              onChange={(e) => setEthnicity(e.target.value)}
              placeholder="Enter ethnicity"
              className="edit-personal-input"
            />
          </div>
        </form>
        <div className="edit-personal-popup-footer">
          <button className="edit-personal-save-button" onClick={handleSave}>
            Save
          </button>
          <button className="edit-personal-cancel-button" onClick={onClose}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

EditPersonalPopup.propTypes = {
  initialInfo: PropTypes.shape({
    age: PropTypes.string,
    location: PropTypes.string,
    ethnicity: PropTypes.string,
  }),
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default EditPersonalPopup;
