import { Outlet, Navigate } from "react-router-dom";
import React from "react";

const PrivateRoutes = () => {
  const auth = localStorage.getItem("isAuthenticated");

  return auth ? <Outlet /> : <Navigate to="/register" />;
};

export default PrivateRoutes;
