import React from "react";
import Layout from "../../components/layout/Layout";
import Reviews from "../../components/review/Reviews";

const Review = () => {
  return (
    <Layout>
      <div>
        <Reviews />
      </div>
    </Layout>
  );
};

export default Review;
