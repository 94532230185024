import React from "react";
import "./actor.css";

const ProfileTabs = ({ activeTab, handleTabClick }) => {
  return (
    <div className="log-profile-tabs">
      <button
        className={activeTab === "info" ? "active" : ""}
        onClick={() => handleTabClick("info")}
      >
        Info
      </button>
      <button
        className={activeTab === "assets" ? "active" : ""}
        onClick={() => handleTabClick("assets")}
      >
        Assets
      </button>
    </div>
  );
};

export default ProfileTabs;
