import React from "react";
import Layout from "../../components/layout/Layout";
import Top from "../../components/home/Top";
import Middle from "../../components/home/Middle";
import Bottom from "../../components/home/Bottom";
import Crew from "../../components/home/Crew";

const Home = () => {
  return (
    <Layout>
      <div>
        <Top />
        {/* <Middle/>
        <Crew />
        <Bottom /> */}
      </div>
    </Layout>
  );
};

export default Home;
