import React from 'react';

const ProfilePhoto = ({ headshot, handleImageChange }) => (
  <div className="profile-photo">
    <img src={headshot} alt="Headshot" />
    <p>
      <br />
      Min resolution:
      <br />
      600x800 px
    </p>
    <input
      type="file"
      accept="image/*"
      onChange={handleImageChange}
      style={{ display: "none" }}
      id="headshot-upload"
    />
    <label htmlFor="headshot-upload" className="upload-button">
      Upload
    </label>
  </div>
);

export default ProfilePhoto;
