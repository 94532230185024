import React, { useState, useEffect, useContext } from "react";
import "./review.css";
import Avatar from "../../assets/avatar.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import Rating from "@mui/material/Rating";
import Pagination from "@mui/material/Pagination";
import ReviewAddModal from "./ReviewAddModal";
import { getAllReviews } from "../../api/axios";
import nprogress from "nprogress";
import "nprogress/nprogress.css";
import { Spinner } from "react-bootstrap";
import { AuthContext } from "../../context/AuthContext";

const Reviews = () => {
  const { isAuthenticated } = useContext(AuthContext);
  const [reviews, setReviews] = useState([]);
  const [page, setPage] = useState(1);
  const [openReviewAddModal, setOpenReviewAddModal] = useState(false);
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  //fetch all reviews
  const GetAllReviews = async () => {
    nprogress.start();
    try {
      const data = await getAllReviews();
      if (data.success) {
        setReviews(data.users);
      }
    } catch (error) {
      console.log("Error fetching reviews", error);
    } finally {
      nprogress.done();
      setLoading(false);
    }
  };

  useEffect(() => {
    GetAllReviews();
  }, []);

  // Update screen size on window resize
  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  //pagination
  const handleChange = (event, value) => {
    setPage(value);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const reviewsPerColumnLarge = 4;
  const reviewsPerColumnMedium = 4;
  const reviewsPerPageLarge = 12;
  const reviewsPerPageMedium = 8;
  const reviewsPerPageSmall = 8;

  const splitIntoColumns = (reviews) => {
    if (screenSize < 577) {
      return [reviews];
    }

    const isMediumScreen = screenSize >= 577 && screenSize <= 992;
    const columns = isMediumScreen ? [[], []] : [[], [], []];
    const reviewsPerColumn = isMediumScreen
      ? reviewsPerColumnMedium
      : reviewsPerColumnLarge;
    reviews.forEach((review, index) => {
      columns[Math.floor(index / reviewsPerColumn) % columns.length].push(
        review
      );
    });
    return columns;
  };

  const paginatedReviews = () => {
    const isMediumScreen = screenSize >= 577 && screenSize <= 992;
    const isSmallScreen = screenSize < 577;
    const reviewsPerPage = isSmallScreen
      ? reviewsPerPageSmall
      : isMediumScreen
      ? reviewsPerPageMedium
      : reviewsPerPageLarge;
    return reviews?.slice((page - 1) * reviewsPerPage, page * reviewsPerPage);
  };

  const columns = splitIntoColumns(paginatedReviews());

  //open review add modal
  const handleClickAddReview = () => {
    setOpenReviewAddModal(true);
  };

  if (loading) {
    return (
      <div className="spinner-page">
        <Spinner className="spinner" />
      </div>
    );
  }
  return (
    <div className="reviews-full-wrapper">
      <div className="reviews-container">
        <div>
          <span className="all-role-count">{reviews?.length} Reviews</span>
        </div>
        {isAuthenticated && (
          <div>
            <button className="homeMiddle2" onClick={handleClickAddReview}>
              TELL YOUR STORY
            </button>
          </div>
        )}
        <div className="seperate-line"></div>
        <div className="review-list-container">
          {columns?.map((column, columnIndex) => (
            <div className="review-list-column" key={columnIndex}>
              {column.map((review, reviewIndex) => (
                <div className="review-review-card" key={reviewIndex}>
                  <div className="review-authImge">
                    <img
                      src={review?.roleData.imageURL || Avatar}
                      alt=""
                      className="review-auth-avatar"
                    />
                  </div>
                  <div>
                    <span className="review-authName">
                      {review?.review.User.firstName}{" "}
                      {review?.review.User.lastName},{" "}
                    </span>
                    <span className="review-authRole">
                      {review?.review.User.role}
                    </span>
                  </div>
                  <div className="review-date-rate">
                    <span>
                      <FontAwesomeIcon icon={faCalendarAlt} />{" "}
                      {review?.review.createdDate}
                    </span>
                    <span>
                      <Rating
                        name="read-only"
                        value={review?.review.rateValue}
                        readOnly
                        sx={{ fontSize: "18px" }}
                      />
                    </span>
                  </div>
                  <div className="seperate-line"></div>
                  <span>{review?.review.description}</span>
                </div>
              ))}
            </div>
          ))}
        </div>
        <div className="pagination">
          <Pagination
            count={Math.ceil(
              reviews.length /
                (screenSize < 577
                  ? reviewsPerPageSmall
                  : screenSize >= 577 && screenSize <= 992
                  ? reviewsPerPageMedium
                  : reviewsPerPageLarge)
            )}
            boundaryCount={1}
            page={page}
            onChange={handleChange}
            sx={{
              "& .MuiPaginationItem-root": {
                color: "#e54447",
                "&:hover": {
                  backgroundColor: "rgba(229, 68, 71, 0.1)",
                },
              },
              "& .MuiPaginationItem-root.Mui-selected": {
                backgroundColor: "#e54447",
                color: "#fff",
                "&:hover": {
                  backgroundColor: "#d43d3f",
                },
              },
              "& .MuiPaginationItem-ellipsis": {
                color: "#e54447",
                "&:hover": {
                  backgroundColor: "transparent",
                },
              },
            }}
          />
        </div>
        <ReviewAddModal
          openReviewAddModal={openReviewAddModal}
          setOpenReviewAddModal={setOpenReviewAddModal}
          GetAllReviews={GetAllReviews}
        />
      </div>
    </div>
  );
};

export default Reviews;
