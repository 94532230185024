import childIcon from './../../assets/child-icon.png'
import netIcon from './../../assets/net-icon.png'


export const aboutUsData = {
    title: "About Open Curtains Casting",
    whoWeAre: {
      title: "Who We Are",
      description:
        "Open Curtains Casting's goal is to create a top-notch online experience for Actors and Crew to find jobs in the film industry while giving Casting Directors a wide selection of outstanding talent.",
      
    },
    features: [
      {
        title: "Build a professional portfolio for your child",
        icon: netIcon,
      },
      {
        title: "Apply to a variety of casting calls all over the U.S.",
        icon: childIcon,
      },
      {
        title: "Network with fellow parents & casting directors",
        icon: netIcon,
      },
    ],
    whatWeDo: {
      title: "What We Do",
      description:
        "KidsCasting allows you to create a portfolio for your child, which is similar to a CV. A portfolio should include your child's interests and skills, as well as a brief description of their looks (hair color, eye color etc.). This portfolio also should include a couple photos of your child. Once it's done, your child's portfolio is added to our database and we're set to apply to casting calls!",
    },
    benefits: {
      title: "What KidsCasting can give You",
      items: [
        {
          title: "Development benefits",
          description:
            "Creative activities play an extremely important role in child's development. Introducing your child to acting and modeling will not only become their favourite new hobby, but it can give them important and useful skills that they will have for the rest of their life. For example, elevate their confidence levels, be a great tool of self-expression and introduce them to many new friendships.",
        },
        {
          title: "Financial benefits",
          description:
            "Modeling and acting jobs for kids can also be rewarding financially. KidsCasting offers many paid opportunities for kids of all ages, from baby modeling to acting and performing. Whether you'd like some extra funds for your kid's toys or are looking for something that can help your kids once they're in college, Modeling and Acting is beneficial in every way. Especially with college tuitions rising each year. So why not have your child practice something they truly enjoy and earn extra at the same time?",
        },
        
      ],
    },
    contactInfo: {
      phone: "1-800-981-1902",
      hours: "Monday-Friday 8am-5pm PST",
      email: "support@kidscasting.com",
    },
    otherInfo: [
      { title: "Subscription Cancellation", link: "#" },
      { title: "Refund Policy", link: "#" },
      { title: "Prices", link: "#" },
    ],
    legal: [
      { title: "Terms of Use", link: "#" },
      { title: "Privacy Policy", link: "#" },
      { title: "Labor Laws", link: "#" },
    ],
  };
  