import React from "react";
import { aboutUsData } from "./aboutUsData.js";
import "./aboutUs.css";

const KidsCastingPage = () => {
  return (
    <div className="casting-page">
      <h1 className="title">{aboutUsData.title}</h1>

      <section className="who-we-are">
        <h2>{aboutUsData.whoWeAre.title}</h2>
        <p>{aboutUsData.whoWeAre.description}</p>
      </section>      
      {/* <section className="features">
        <h3>Your ONLY resource dedicated to kids auditions</h3>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. At,
          architecto.
        </p>
        <div className="feature-list">
          {aboutUsData.features.map((feature, index) => (
            <div key={index} className="feature-item">
              <img src={feature.icon} alt=" " />
              <p>{feature.title}</p>
            </div>
          ))}
        </div>
        <button>Join Now</button>
      </section>

      <section className="what-we-do">
        <h2>{aboutUsData.whatWeDo.title}</h2>
        <p>{aboutUsData.whatWeDo.description}</p>
      </section>

      <section className="benefits">
        <h2>{aboutUsData.benefits.title}</h2>
        {aboutUsData.benefits.items.map((benefit, index) => (
          <div key={index} className="benefit-item">
            <h3>{benefit.title}</h3>
            <p>{benefit.description}</p>
          </div>
        ))}
      </section>

      <section className="contact-info">
        <p>Phone: {aboutUsData.contactInfo.phone}</p>
        <p>Hours: {aboutUsData.contactInfo.hours}</p>
        <p>Email: {aboutUsData.contactInfo.email}</p>
      </section>

      <section className="other-info">
        <h3>Other Info</h3>
        <ul>
          {aboutUsData.otherInfo.map((item, index) => (
            <li key={index}>
              <a href={item.link}>{item.title}</a>
            </li>
          ))}
        </ul>
      </section>

      <section className="legal">
        <h3>Legal</h3>
        <ul>
          {aboutUsData.legal.map((item, index) => (
            <li key={index}>
              <a href={item.link}>{item.title}</a>
            </li>
          ))}
        </ul>
      </section> */}
    </div>
  );
};

export default KidsCastingPage;
