import React, { useState, useEffect, useContext } from "react";
import {
  Dialog,
  DialogContent,
  TextField,
  Button,
  Typography,
  Box,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { styled } from "@mui/system";
import { Visibility, VisibilityOff, Close, VerticalAlignTop } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { SignIn } from "../../api/axios";
import Alert from "../common/Alert";
import { AuthContext } from "../../context/AuthContext";

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    borderRadius: "10px",
    padding: theme.spacing(2),
    maxWidth: "400px",
    width: "100%",
  },
}));

function LoginPopup({ isLoginOpen, setIsLoginOpen }) {
  const {login} = useContext(AuthContext);
  const navigate = useNavigate();
  const initialValue = {
    email: "",
    password: "",
  };
  const [formData, setFormData] = useState(initialValue);
  const [formError, setFormError] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isFormComplete, setIsFormComplete] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");
  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const onClose = () => {
    setIsLoginOpen(!isLoginOpen);
    setFormData(initialValue);
    setFormError({});
  };

  const handleJoinNow = () => {
    onClose();
    navigate("/register");
  };

  const handleInputChange = (name, value) => {
    setFormError({
      ...formError,
      [name]: "",
    });

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  useEffect(() => {
    const isComplete =
      formData.email.trim() !== "" && formData.password.trim() !== "";
    setIsFormComplete(isComplete);
  }, [formData]);

  const validateForm = () => {
    const errors = {};

    if (!formData.email) {
      errors.email = "Please enter your email";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Please enter valid email";
    }
    if (!formData.password) {
      errors.password = "Please enter your password";
    } else if (
      !/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/.test(
        formData.password
      )
    ) {
      errors.password =
        "Password must include at least 8 characters, a symbol, a capital letter, a simple letter, and a number";
    }

    setFormError(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (validateForm()) {
      try {
        const body = {
          email: formData.email,
          password: formData.password,
        };
        const res = await SignIn(body);
        if (res.success) {
          setAlertSeverity("success");
          setAlertMessage("Sign in Successfull!");
          login(res.token);
          onClose();
          setTimeout(() => {
            navigate("/profile");
          }, 500);
        }
      } catch (error) {
        console.error("Login failed:", error);
        setAlertSeverity("error");
        setAlertMessage(error.response.data.message);
      } finally {
        setLoading(false);
        setOpenSnackbar(true);
      }
    } else {
      setLoading(false);
    }
  };

  return (
    <StyledDialog open={isLoginOpen} onClose={onClose}>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Close />
      </IconButton>
      <DialogContent>
        <Typography
          variant="h5"
          align="center"
          gutterBottom
          fontWeight="bold"
          fontFamily="Poppins, sans-serif"
        >
          Log in to Open Curtains Casting
        </Typography>
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
          <TextField
            fullWidth
            variant="filled"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={formData.email}
            onChange={(e) => handleInputChange("email", e.target.value)}
            error={!!formError.email}
            helperText={formError.email}
            sx={{
              backgroundColor: "transparent",
              "& .MuiFilledInput-root": {
                backgroundColor: "transparent",
              },
            }}
          />
          <TextField
            margin="normal"
            fullWidth
            variant="filled"
            name="password"
            label="Password"
            type={showPassword ? "text" : "password"}
            autoComplete="current-password"
            value={formData.password}
            onChange={(e) => handleInputChange("password", e.target.value)}
            error={!!formError.password}
            helperText={formError.password}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            sx={{
              backgroundColor: "transparent",
              "& .MuiFilledInput-root": {
                backgroundColor: "transparent",
              },
            }}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            disabled={!isFormComplete || loading}
            sx={{
              mt: 3,
              mb: 2,
              backgroundColor: "#e54447 !important",
              color: "white !important",
              borderRadius: "40px !important",
              textTransform: "capitalize !important",
              "&:hover": { backgroundColor: "#f06263 !important" },
              "&.Mui-disabled": {
                backgroundColor: "#e54447 !important",
                opacity: 0.5,
              },
            }}
          >
            {loading ? "Loading..." : "Log In"}
          </Button>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              variant="body2"
              color="primary"
              sx={{ cursor: "pointer", fontFamily: "Poppins, sans-serif", marginBottom: 1}}
            >
              Forgot password?
            </Typography>
            <Typography variant="body2" fontFamily="Poppins, sans-serif">
              Not a member?{" "}
              <span
                onClick={handleJoinNow}
                style={{ color: "#e54447", cursor: "pointer" }}
              >
                Join now
              </span>
            </Typography>
          </Box>
        </Box>
        <Alert
          openSnackbar={openSnackbar}
          alertSeverity={alertSeverity}
          setOpenSnackbar={setOpenSnackbar}
          alertMessage={alertMessage}
        />
      </DialogContent>
    </StyledDialog>
  );
}

export default LoginPopup;
