import React, { useState, useEffect } from "react";
import EditPopup from "../../EditPopup/EditPopup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import {
  updateSocialNetworkLink,
  updateEmail,
  updateContactNumber,
  getUserDetails, // Ensure this is imported correctly
} from "../../../api/axios";
import HeadshotUpload from "../../EditPopup/HeadshotUpload";
import Alert from "../../common/Alert";

const ProfileSidebar = ({ setHeadshot, userDetails, GetUserDetails, userId }) => {
  const [isEmailPopupVisible, setIsEmailPopupVisible] = useState(false);
  const [isContactPopupVisible, setIsContactPopupVisible] = useState(false);
  const [isSocialPopupVisible, setIsSocialPopupVisible] = useState(false);
  const [socialNetworks, setSocialNetworks] = useState({
    facebook: "",
    instagram: "",
    xlink: "",
    tiktok: "",
    youtube: "",
  });
  const [currentSocialNetwork, setCurrentSocialNetwork] = useState({});
  const [isEditingSocialNetwork, setIsEditingSocialNetwork] = useState(false);
  
  // State for managing alerts
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");

  const userMainDetails = userDetails?.registerResult || {};

  // Initialize local state with userMainDetails
  const [email, setEmail] = useState(userMainDetails.email || "example@example.com");
  const [contactNumber, setContactNumber] = useState(userMainDetails.contactNumber || "123-456-7890");

  // Update state whenever userDetails change
  useEffect(() => {
    setEmail(userMainDetails.email || "example@example.com");
    setContactNumber(userMainDetails.contactNumber || "123-456-7890");
  }, [userMainDetails]);

  useEffect(() => {
    const loadUserDetails = async () => {
      try {
        const userData = await getUserDetails(userId);
        const socialLinks = userData.user.result || {};

        setSocialNetworks({
          facebook: socialLinks.fbLink || "",
          instagram: socialLinks.instagramLink || "",
          xlink: socialLinks.xLink || "",
          tiktok: socialLinks.tikTokLink || "",
          youtube: socialLinks.youtubeLink || "",
        });
      } catch (error) {
        console.error("Error fetching user details:", error);
        setAlertMessage("Failed to load user details.");
        setAlertSeverity("error");
        setOpenSnackbar(true);
      }
    };

    loadUserDetails();
  }, [userId]);

  const handleEditClick = (type) => {
    if (type === "email") {
      setIsEmailPopupVisible(true);
    } else if (type === "contact") {
      setIsContactPopupVisible(true);
    } else if (type === "social") {
      setCurrentSocialNetwork(socialNetworks);
      setIsEditingSocialNetwork(true);
      setIsSocialPopupVisible(true);
    }
  };

  const handleAddClick = () => {
    setCurrentSocialNetwork({
      facebook: "",
      instagram: "",
      xlink: "",
      tiktok: "",
      youtube: "",
    });
    setIsEditingSocialNetwork(false);
    setIsSocialPopupVisible(true);
  };

  const handleSaveEmail = async (newEmail) => {
    try {
      const updatedUser = await updateEmail(userId, newEmail.email);
      console.log("API Response:", updatedUser);
      if (updatedUser) {
        setEmail(newEmail.email);
        GetUserDetails(); // Optionally refresh user details
        // Trigger success alert
        setAlertMessage("Email updated successfully!");
        setAlertSeverity("success");
        setOpenSnackbar(true);
      }
    } catch (error) {
      console.error("Error updating email:", error);
      // Trigger error alert
      setAlertMessage("Failed to update email.");
      setAlertSeverity("error");
      setOpenSnackbar(true);
    }
    setIsEmailPopupVisible(false);
  };

  const handleSaveContact = async (newContact) => {
    try {
      const updatedUser = await updateContactNumber(userId, newContact.contactNumber);
      if (updatedUser) {
        setContactNumber(newContact.contactNumber);
        GetUserDetails(); // Optionally refresh user details
        // Trigger success alert
        setAlertMessage("Contact number updated successfully!");
        setAlertSeverity("success");
        setOpenSnackbar(true);
      }
    } catch (error) {
      console.error("Error updating contact number:", error);
      // Trigger error alert
      setAlertMessage("Failed to update contact number.");
      setAlertSeverity("error");
      setOpenSnackbar(true);
    }
    setIsContactPopupVisible(false);
  };

  const handleSaveSocialNetwork = async (newSocialNetworks) => {
    try {
      for (const [platform, link] of Object.entries(newSocialNetworks)) {
        if (link) {
          await updateSocialNetworkLink(userId, platform, link);
        }
      }
      setSocialNetworks(newSocialNetworks);
      setAlertMessage("Social networks updated successfully!");
      setAlertSeverity("success");
      setOpenSnackbar(true);
    } catch (error) {
      console.error("Error updating social networks:", error);
      setAlertMessage("Failed to update social networks.");
      setAlertSeverity("error");
      setOpenSnackbar(true);
    }
    setIsSocialPopupVisible(false);
  };

  const handleClosePopup = () => {
    setIsEmailPopupVisible(false);
    setIsContactPopupVisible(false);
    setIsSocialPopupVisible(false);
  };

  return (
    <div className="log-profile-sidebar">
      <HeadshotUpload
        setHeadshot={setHeadshot}
        imgUrl={userMainDetails?.imageURL}
        GetUserDetails={GetUserDetails}
        userId={userId}
      />
      <div className="log-profile-contact-info">
        <div className="log-contact-option">
          <div className="log-contact-label">
            <span>Email</span>
            <FontAwesomeIcon
              icon={faEdit}
              className="editIcon"
              onClick={() => handleEditClick("email")}
            />
          </div>
          <div className="log-profile-details-box">
            {email}
          </div>
        </div>
        <div className="log-contact-option">
          <div className="log-contact-label">
            <span>Contact Number</span>
            <FontAwesomeIcon
              icon={faEdit}
              className="editIcon"
              onClick={() => handleEditClick("contact")}
            />
          </div>
          <div className="log-profile-details-box">
            {contactNumber}
          </div>
        </div>
        <div className="log-contact-option" style={{ marginBottom: '20px' }}>
          <div className="log-contact-label" style={{ marginBottom: '10px' }}>
            <span>Social Networks</span>
          </div>
          <div className="log-profile-details-box" style={{ display: 'grid', gap: '10px', gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))' }}>
            {Object.entries(socialNetworks).every(([key, value]) => !value) ? (
              <p
                className="clickable-text"
                onClick={handleAddClick}
                style={{ cursor: 'pointer', color: '#007bff', textDecoration: 'underline' }}
              >
                Add social network URLs
              </p>
            ) : (
              Object.entries(socialNetworks).map(
                ([key, value]) =>
                  value && (
                    <div
                      key={key}
                      className="social-network-item"
                      style={{
                        padding: '10px',
                        border: '1px solid #ddd',
                        borderRadius: '4px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        backgroundColor: '#f9f9f9'
                      }}
                    >
                      <span>
                        {key.charAt(0).toUpperCase() + key.slice(1)}: {value}
                      </span>
                      <FontAwesomeIcon
                        icon={faEdit}
                        className="edit-icon"
                        onClick={() => handleEditClick("social")}
                        style={{ cursor: 'pointer', color: '#007bff' }}
                      />
                    </div>
                  )
              )
            )}
          </div>
        </div>
      </div>

      {isEmailPopupVisible && (
        <EditPopup
          title="Edit Email"
          fields={{ email }}
          onSave={handleSaveEmail}
          onClose={handleClosePopup}
        />
      )}
      {isContactPopupVisible && (
        <EditPopup
          title="Edit Contact Number"
          fields={{ contactNumber }}
          onSave={handleSaveContact}
          onClose={handleClosePopup}
        />
      )}
      {isSocialPopupVisible && (
        <EditPopup
          title={
            isEditingSocialNetwork
              ? "Edit Social Networks"
              : "Add Social Networks"
          }
          fields={currentSocialNetwork}
          onSave={handleSaveSocialNetwork}
          onClose={handleClosePopup}
        />
      )}

     
      {/* Alert Component */}
      <Alert
        openSnackbar={openSnackbar}
        alertSeverity={alertSeverity}
        setOpenSnackbar={setOpenSnackbar}
        alertMessage={alertMessage}
      />
    </div>
  );
};

export default ProfileSidebar;