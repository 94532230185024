import React from "react";
import Layout from "../../components/layout/Layout";
import ActorsList from "../../components/actors/ActorsList";
import CastingCallSlide from "../../components/castingCalls/castingCallSlide";

const Actors = () => {
  return (
    <Layout>
      <div>
        <CastingCallSlide />
        <ActorsList />
      </div>
    </Layout>
  );
};

export default Actors;
