import React, {useEffect} from "react";
import SubscriptionPlans from "../../components/subscription/SubscriptionPlans";
import SubscriptionNavBar from "../../components/subscription/SubscriptionNavBar";
import Footer from "../../components/layout/Footer";

const SubscriptionPlan = () => {

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return(
    < div style={{marginTop:"-85px"}}>
    <SubscriptionNavBar />
    <SubscriptionPlans />
    <Footer />
    </div>
  ) 
};

export default SubscriptionPlan;
