import React, { useEffect, useState, useRef } from "react";
import "./castingCall.css";
import {
  TextField,
  InputLabel,
  FormControl,
  MenuItem,
  Select,
  Radio,
  RadioGroup,
  FormControlLabel,
  Checkbox,
  Typography,
} from "@mui/material";
import RoleAddModal from "./RoleAddModal";
import {
  addRoleToCastingCall,
  editCastingCall,
  getCastingCallByID,
  deleteRole,
  editRole,
} from "../../api/axios";
import { useParams, useNavigate } from "react-router-dom";
import Alert from "../common/Alert";
import nprogress from "nprogress";
import "nprogress/nprogress.css";
import { Spinner } from "react-bootstrap";
import RoleEditModal from "./RoleEditModal";

const CastingCallEdit = () => {
  const { id } = useParams();
  const detailRef = useRef(null);
  const dateRef = useRef(null);
  const paymentRef = useRef(null);
  const companyRef = useRef(null);
  const roleRef = useRef(null);
  const navigate = useNavigate();

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const days = Array.from({ length: 31 }, (_, i) => i + 1);
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 10 }, (_, i) => currentYear + i);

  const initialValue = {
    title: "",
    category: "",
    zip: "",
    description: "",
    area: "",
    check: false,
    month: "",
    day: "",
    year: "",
    amount: "",
    period: "",
    companyTitle: "",
    companyNumber: "",
    companyUrl: "",
    roles: [],
  };
  const [formData, setFormData] = useState(initialValue);
  const [formError, setFormError] = useState({});

  const [openRoleModal, setOpenRoleModal] = useState(false);
  const [openRoleEditModal, setOpenRoleEditModal] = useState(false);
  const [selectedEditRole, setSelectedEditRole] = useState("");

  const [loading, setLoading] = useState(true);
  const [loadingBtn, setLoadingBtn] = useState(false);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");

  const currentDate = new Date();

  //auto scroll to page top
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  //fetch casting details for update
  const GetCastingCallByID = async (id) => {
    nprogress.start();
    try {
      const res = await getCastingCallByID(id);
      if (res.success) {
        const casting = res.casting;
        if (casting.roles && casting.roles.length > 0) {
          casting.roles = casting.roles.map((role) => {
            if (role.ethinicity) {
              role.ethinicity = JSON.parse(role.ethinicity);
            }
            return role;
          });
        }
        const [month, day, year] = casting.expirationDate.split("/");

        setFormData({
          title: casting.title,
          category: casting.category,
          zip: casting.zipCode,
          description: casting.shortDesc,
          area: casting.area,
          check: casting.auditionType === "1" ? true : false,
          month: month,
          day: day,
          year: year,
          amount: casting.amount,
          period: casting.period,
          companyTitle: casting.companyName,
          companyNumber: casting.contactNo,
          companyUrl: casting.websiteURL,
          roles: casting.roles,
        });
      }
    } catch (error) {
      console.log("error fetching casting call", error);
    } finally {
      nprogress.done();
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      GetCastingCallByID(id);
    }
  }, [id]);

  //save changed inputs
  const handleInputChange = (name, value) => {
    setFormError({
      ...formError,
      [name]: "",
    });

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  //auto scroll for ref
  const scrollToRef = (ref) => {
    if (ref) {
      const navbarHeight = 105;
      const topPosition =
        ref.getBoundingClientRect().top + window.scrollY - navbarHeight;
      window.scrollTo({ top: topPosition, behavior: "smooth" });
    }
  };

  //role add modal open
  const handleClickAddRole = () => {
    setOpenRoleModal(true);
  };

  //role edit modal open
  const handleClickEditRole = (role) => {
    setOpenRoleEditModal(true);
    setSelectedEditRole(role);
  };

  //add new role for created casting call.
  const handleAddRole = async (role) => {
    setFormData((prevData) => ({
      ...prevData,
      roles: [...prevData.roles, role],
    }));
    if (role) {
      try {
        const body = {
          castingId: id,
          castingRole: role.castingRole,
          title: role.title,
          desc: role.desc,
          ageMin: role.ageMin,
          ageMax: role.ageMax,
          gender: role.gender,
          ethinicity: role.ethinicity,
        };
        const res = await addRoleToCastingCall(body);
        if (res.success) {
          setAlertSeverity("success");
          setAlertMessage("New role add successfull!");
          GetCastingCallByID(id);
        }
      } catch (error) {
        console.log("New role add faild!", error);
        setAlertSeverity("error");
        setAlertMessage("New role add faild!");
      } finally {
        setOpenSnackbar(true);
      }
    }
  };

  //edit role in casting call
  const handleEditRole = async (role) => {
    setFormData((prevData) => ({
      ...prevData,
      roles: prevData.roles.map((item) =>
        item.id === selectedEditRole.id ? role : item
      ),
    }));
    if (role) {
      try {
        const body = {
          castingRole: role.castingRole,
          title: role.title,
          desc: role.description,
          ageMin: role.ageMin,
          ageMax: role.ageMax,
          gender: role.gender,
          ethinicity: role.ethinicity,
        };
        const res = await editRole(selectedEditRole.id, body);
        if (res.success) {
          setAlertSeverity("success");
          setAlertMessage("Role updated!");
        }
      } catch (error) {
        console.log("Role update faild!", error);
        setAlertSeverity("error");
        setAlertMessage("Role update faild!");
      } finally {
        setOpenSnackbar(true);
      }
    }
  };

  //added role remove from casting call
  const handleDeleteRole = async (id) => {
    setFormData((prevData) => ({
      ...prevData,
      roles: prevData.roles.filter((role) => role.id !== id),
    }));
    if (id) {
      try {
        const res = await deleteRole(id);
        if (res.success) {
          setAlertSeverity("success");
          setAlertMessage("Role removed!");
        }
      } catch (error) {
        console.log("Role remove faild!", error);
        setAlertSeverity("error");
        setAlertMessage("Role remove faild!");
      } finally {
        setOpenSnackbar(true);
      }
    }
  };

  //validate edit form
  const validateForm = () => {
    const errors = {};

    if (!formData.title) {
      errors.title = "Please add casting call title";
    }
    if (!formData.category) {
      errors.category = "Please select a category";
    }
    if (!formData.zip) {
      errors.zip = "Please add Zip/ Postal code";
    }
    if (!formData.description) {
      errors.description = "Please add small description";
    }
    if (!formData.month) {
      errors.month = "Please select a month";
    }
    if (!formData.day) {
      errors.day = "Please select a day";
    }
    if (!formData.year) {
      errors.year = "Please select a year";
    }
    if (formData.month && formData.day && formData.year) {
      const selectedDate = new Date(
        `${formData.year}-${formData.month}-${formData.day}`
      );
      if (selectedDate < currentDate) {
        errors.date = "Expiration date should be  no earlier than tommorow ";
      }
    }
    if (formData.amount && formData.amount <= 0) {
      errors.amount = "Amount should be greater than 0";
    }
    if (formData.amount && !formData.period) {
      errors.period = "Please select a period for amount";
    }
    if (formData.roles?.length === 0) {
      errors.roles = "Please add atleast one role";
    }

    setFormError(errors);
    return Object.keys(errors).length === 0;
  };

  //casting call update
  const handleClickEditCastingCall = async () => {
    if (validateForm()) {
      setLoadingBtn(true);

      const body = {
        title: formData.title,
        category: formData.category,
        zipCode: formData.zip,
        shortDesc: formData.description,
        auditionType: formData.check,
        expirationDate: `${formData.month}/${formData.day}/${formData.year}`,
        companyName: formData.companyTitle,
        contactNo: formData.companyNumber,
        websiteURL: formData.companyUrl,
        area: formData.area,
        amount: formData.amount,
        period: formData.period,
      };
      try {
        const res = await editCastingCall(id, body);
        if (res.success) {
          setFormData(initialValue);
          setAlertSeverity("success");
          setAlertMessage("Casting call update successfull!");
          setTimeout(() => {
            navigate("/castingcalls");
          }, 1000);
        }
      } catch (error) {
        console.log("Casting call update faild!", error);
        setAlertSeverity("error");
        setAlertMessage("Casting call update faild!");
      } finally {
        setOpenSnackbar(true);
        setLoadingBtn(false);
      }
    }
  };

  if (loading) {
    return (
      <div className="spinner-page">
        <Spinner className="spinner" />
      </div>
    );
  }
  return (
    <div className="castingCall-view-full-wrapper">
      <div className="castingCall-view-container">
        <div className="castingCall-view-grid">
          <div className="castingCall-view-details">
            <span className="all-role-count">Edit your casting call</span>
            <span className="castingCall-create-section-topic" ref={detailRef}>
              Details
            </span>
            <TextField
              label="Casting call title"
              name="title"
              value={formData.title}
              onChange={(e) => handleInputChange("title", e.target.value)}
              variant="filled"
              fullWidth
              sx={{
                backgroundColor: "transparent",
                "& .MuiFilledInput-root": {
                  backgroundColor: "transparent",
                },
              }}
              error={!!formError.title}
              helperText={formError.title}
            />
            <div className="grid-inputs">
              <FormControl
                fullWidth
                variant="filled"
                sx={{
                  backgroundColor: "transparent",
                  "& .MuiFilledInput-root": {
                    backgroundColor: "transparent",
                  },
                }}
              >
                <InputLabel>Categories</InputLabel>
                <Select
                  value={formData.category}
                  onChange={(e) =>
                    handleInputChange("category", e.target.value)
                  }
                  name="category"
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                >
                  <MenuItem value="acting">Acting</MenuItem>
                  <MenuItem value="modeling">Modeling</MenuItem>
                  <MenuItem value="pageant">Pageant</MenuItem>
                  <MenuItem value="tvShows">Tv Shows</MenuItem>
                  <MenuItem value="theater">Theater</MenuItem>
                  <MenuItem value="voice&Movies">
                    Voice over and Movies
                  </MenuItem>
                </Select>
                {formError.category && (
                  <Typography
                    variant="caption"
                    color="error"
                    sx={{ ml: "15px" }}
                  >
                    {formError.category}
                  </Typography>
                )}
              </FormControl>
              <TextField
                label="Zip/ Postal code"
                name="zip"
                value={formData.zip}
                onChange={(e) => handleInputChange("zip", e.target.value)}
                variant="filled"
                fullWidth
                sx={{
                  backgroundColor: "transparent",
                  "& .MuiFilledInput-root": {
                    backgroundColor: "transparent",
                  },
                }}
                error={!!formError.zip}
                helperText={formError.zip}
              />
            </div>
            <FormControl>
              <RadioGroup
                row
                name="area"
                value={formData.area}
                onChange={(e) => handleInputChange("area", e.target.value)}
              >
                <FormControlLabel
                  value="local"
                  control={<Radio />}
                  label="Local"
                  sx={{ color: "gray" }}
                />
                <FormControlLabel
                  value="nation"
                  control={<Radio />}
                  label="Nationwide"
                  sx={{ color: "gray" }}
                />
                <FormControlLabel
                  value="world"
                  control={<Radio />}
                  label="Worldwide"
                  sx={{ color: "gray" }}
                />
              </RadioGroup>
            </FormControl>
            <TextField
              label="Write a short description"
              name="description"
              value={formData.description}
              onChange={(e) => handleInputChange("description", e.target.value)}
              variant="filled"
              fullWidth
              sx={{
                backgroundColor: "transparent",
                "& .MuiFilledInput-root": {
                  backgroundColor: "transparent",
                },
              }}
              error={!!formError.description}
              helperText={formError.description}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.check}
                  onChange={(e) => handleInputChange("check", e.target.checked)}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label="Online audition (Online auditions means your auditions will take place remotely)"
              sx={{ color: "gray" }}
            />
            <span className="castingCall-create-section-topic" ref={dateRef}>
              Expiration date
            </span>
            <div className="castingCall-dropdown-list">
              <FormControl
                fullWidth
                variant="filled"
                sx={{
                  backgroundColor: "transparent",
                  "& .MuiFilledInput-root": {
                    backgroundColor: "transparent",
                  },
                }}
              >
                <InputLabel>Month</InputLabel>
                <Select
                  value={formData.month}
                  onChange={(e) => handleInputChange("month", e.target.value)}
                  name="month"
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                >
                  {months.map((month, index) => (
                    <MenuItem key={index} value={index + 1}>
                      {month}
                    </MenuItem>
                  ))}
                </Select>
                {formError.month && (
                  <Typography
                    variant="caption"
                    color="error"
                    sx={{ ml: "15px" }}
                  >
                    {formError.month}
                  </Typography>
                )}
              </FormControl>
              <FormControl
                fullWidth
                variant="filled"
                sx={{
                  backgroundColor: "transparent",
                  "& .MuiFilledInput-root": {
                    backgroundColor: "transparent",
                  },
                }}
              >
                <InputLabel>Day</InputLabel>
                <Select
                  value={formData.day}
                  onChange={(e) => handleInputChange("day", e.target.value)}
                  name="day"
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                >
                  {days.map((day) => (
                    <MenuItem key={day} value={day}>
                      {day}
                    </MenuItem>
                  ))}
                </Select>
                {formError.day && (
                  <Typography
                    variant="caption"
                    color="error"
                    sx={{ ml: "15px" }}
                  >
                    {formError.day}
                  </Typography>
                )}
              </FormControl>
              <FormControl
                fullWidth
                variant="filled"
                sx={{
                  backgroundColor: "transparent",
                  "& .MuiFilledInput-root": {
                    backgroundColor: "transparent",
                  },
                }}
              >
                <InputLabel>Year</InputLabel>
                <Select
                  value={formData.year}
                  onChange={(e) => handleInputChange("year", e.target.value)}
                  name="year"
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                >
                  {years.map((year) => (
                    <MenuItem key={year} value={year}>
                      {year}
                    </MenuItem>
                  ))}
                </Select>
                {formError.year && (
                  <Typography
                    variant="caption"
                    color="error"
                    sx={{ ml: "15px" }}
                  >
                    {formError.year}
                  </Typography>
                )}
              </FormControl>
            </div>
            {formError.date && (
              <Typography variant="caption" color="error" sx={{ ml: "15px" }}>
                {formError.date}
              </Typography>
            )}
            <span className="castingCall-create-section-topic" ref={paymentRef}>
              Payment(optional)
            </span>
            <div className="castingCall-dropdown-list">
              <TextField
                label="Amount $"
                name="amount"
                type="number"
                value={formData.amount}
                onChange={(e) => handleInputChange("amount", e.target.value)}
                variant="filled"
                fullWidth
                sx={{
                  backgroundColor: "transparent",
                  "& .MuiFilledInput-root": {
                    backgroundColor: "transparent",
                  },
                  "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
                    {
                      display: "none",
                    },
                }}
                onKeyDown={(e) => {
                  if (e.key === "-" || e.key === "e" || e.key === "E") {
                    e.preventDefault();
                  }
                }}
                error={!!formError.amount}
                helperText={formError.amount}
              />
              <FormControl
                fullWidth
                variant="filled"
                sx={{
                  backgroundColor: "transparent",
                  "& .MuiFilledInput-root": {
                    backgroundColor: "transparent",
                  },
                }}
              >
                <InputLabel>Period</InputLabel>
                <Select
                  value={formData.period}
                  onChange={(e) => handleInputChange("period", e.target.value)}
                  name="period"
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                >
                  <MenuItem value="hour">Hour</MenuItem>
                  <MenuItem value="day">Day</MenuItem>
                  <MenuItem value="week">Week</MenuItem>
                  <MenuItem value="month">Month</MenuItem>
                  <MenuItem value="project">Project</MenuItem>
                  <MenuItem value="tfp">TFP</MenuItem>
                </Select>
                {formError.period && (
                  <Typography
                    variant="caption"
                    color="error"
                    sx={{ ml: "15px" }}
                  >
                    {formError.period}
                  </Typography>
                )}
              </FormControl>
            </div>
            <span className="castingCall-create-section-topic" ref={companyRef}>
              Company info(optional)
            </span>
            <TextField
              label="Company title"
              name="companyTitle"
              value={formData.companyTitle}
              onChange={(e) =>
                handleInputChange("companyTitle", e.target.value)
              }
              variant="filled"
              fullWidth
              sx={{
                backgroundColor: "transparent",
                "& .MuiFilledInput-root": {
                  backgroundColor: "transparent",
                },
              }}
            />
            <div className="grid-inputs">
              <TextField
                label="Company number"
                name="companyNumber"
                value={formData.companyNumber}
                onChange={(e) =>
                  handleInputChange("companyNumber", e.target.value)
                }
                variant="filled"
                fullWidth
                sx={{
                  backgroundColor: "transparent",
                  "& .MuiFilledInput-root": {
                    backgroundColor: "transparent",
                  },
                }}
              />
              <TextField
                label="Company website"
                name="companyUrl"
                value={formData.companyUrl}
                onChange={(e) =>
                  handleInputChange("companyUrl", e.target.value)
                }
                variant="filled"
                fullWidth
                sx={{
                  backgroundColor: "transparent",
                  "& .MuiFilledInput-root": {
                    backgroundColor: "transparent",
                  },
                }}
              />
            </div>
            <div className="castingCall-create-role">
              <span className="all-role-count" ref={roleRef}>
                {formData.roles?.length} Roles
              </span>
              <button className="homeMiddle2" onClick={handleClickAddRole}>
                {formData.roles?.length === 0
                  ? "ADD A ROLE"
                  : "ADD ANOTHER ROLE"}
              </button>
            </div>
            <div className="created-role-list">
              {formData.roles?.map((role) => (
                <div className="created-role-card" key={role.id}>
                  <span>{role.title}</span>
                  <span>{role.gender}</span>
                  <span>
                    {role.ageMin} - {role.ageMax}
                  </span>
                  <span>
                    {role.ethinicity?.map((item, index) => (
                      <span key={index}>{item}, </span>
                    ))}
                  </span>
                  <span>{role.description}</span>
                  <div className="created-role-buttons">
                    <span
                      style={{ color: "red", cursor: "pointer" }}
                      onClick={() => handleDeleteRole(role.id)}
                    >
                      Delete role
                    </span>
                    <span
                      style={{ color: "blue", cursor: "pointer" }}
                      onClick={() => handleClickEditRole(role)}
                    >
                      Edit role
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="castingCall-view-ref-container">
            <div className="castingCall-ref-list">
              <span
                className="castingCall-add-ref"
                onClick={() => scrollToRef(detailRef.current)}
              >
                Add Details
              </span>
              <span
                className="castingCall-add-ref"
                onClick={() => scrollToRef(dateRef.current)}
              >
                Expiration Date
              </span>
              <span
                className="castingCall-add-ref"
                onClick={() => scrollToRef(paymentRef.current)}
              >
                Add Payment(optional)
              </span>
              <span
                className="castingCall-add-ref"
                onClick={() => scrollToRef(companyRef.current)}
              >
                Add Company Info(optional)
              </span>
              <span
                className="castingCall-add-ref"
                onClick={() => scrollToRef(roleRef.current)}
              >
                Add Roles
              </span>
            </div>
            <div className="role-add-button">
              {formError.roles && (
                <Typography variant="caption" color="error">
                  {formError.roles}
                </Typography>
              )}
            </div>
            <div className="role-add-button">
              <button
                className="castingCall-view-apply-button"
                onClick={handleClickEditCastingCall}
              >
                {loadingBtn ? "SAVING.." : "SAVE CHANGES"}
              </button>
            </div>
          </div>
        </div>
        <RoleAddModal
          openRoleModal={openRoleModal}
          setOpenRoleModal={setOpenRoleModal}
          handleAddRole={handleAddRole}
        />
        <RoleEditModal
          openRoleEditModal={openRoleEditModal}
          setOpenRoleEditModal={setOpenRoleEditModal}
          handleEditRole={handleEditRole}
          selectedEditRole={selectedEditRole}
          setSelectedEditRole={setSelectedEditRole}
        />
        <Alert
          openSnackbar={openSnackbar}
          alertSeverity={alertSeverity}
          setOpenSnackbar={setOpenSnackbar}
          alertMessage={alertMessage}
        />
      </div>
    </div>
  );
};

export default CastingCallEdit;
