import React from 'react';
import { NavLink } from 'react-router-dom';
import './subscriptionNavBar.css';
import logo from '../../assets/logo.jpeg';

const SubscriptionNavBar = () => {
  const goBack = () => {
    window.history.back();
  };

  return (
    <div className="subscriptionNavbar-container">
      <NavLink to="/">
        <img src={logo} alt="Logo" className="subscription-logo" />
      </NavLink>
      <button onClick={goBack} className="go-back-button">
        Go Back
      </button>
    </div>
  );
};

export default SubscriptionNavBar;
