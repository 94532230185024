import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import AddPopup from "../../EditPopup/Addpopup";
import EditPersonalPopup from "../../EditPopup/EditPersonalPopup";
import EditAppearancePopup from "../../EditPopup/UpdateAppearance";
import {
  updateAboutContent,
  updateInterestContent,
  updateTalentsContent,
  updateHobbiesContent,
  updateAgeContent,
  updateLocationContent,
  updateEthnicityContent,
  updateHeightContent,
  updateWeightContent,
  updateHairColorContent,
  updateEyeColorContent,
  updateDirectorAboutContent,
  updateDirectorCategoryContent,
  updateDirectorCompanyName,
  updateDirectorLocation,
} from "../../../api/axios";
import Alert from "../../common/Alert";

const ProfileInfo = ({ userDetails, userId, role }) => {
  const userOtherDetails = userDetails?.result || {};

  const [categoryContent, setCategoryContent] = useState(userOtherDetails.category || ""); 
  const [isCategoryPopupVisible, setCategoryPopupVisible] = useState(false);
  const [companyName, setCompanyName] = useState(userOtherDetails.companyName || "");
const [location, setLocation] = useState(userOtherDetails.location || "");
const [isCompanyPopupVisible, setCompanyPopupVisible] = useState(false);
const [isLocationPopupVisible, setLocationPopupVisible] = useState(false);
  const [isAboutPopupVisible, setAboutPopupVisible] = useState(false);
  const [isInterestPopupVisible, setInterestPopupVisible] = useState(false);
  const [isTalentPopupVisible, setTalentPopupVisible] = useState(false);
  const [isHobbiesPopupVisible, setHobbiesPopupVisible] = useState(false);
  const [isAppearancePopupVisible, setAppearancePopupVisible] = useState(false);
  const [isPersonalPopupVisible, setPersonalPopupVisible] = useState(false);
  const [aboutContent, setAboutContent] = useState(
    userOtherDetails.about || ""
  );
  const [interestContent, setInterestContent] = useState(
    userOtherDetails.interestedIn || ""
  );
  const [talentContent, setTalentContent] = useState(
    userOtherDetails.talents || ""
  );
  const [hobbiesContent, setHobbiesContent] = useState(
    userOtherDetails.hobbies || ""
  );
  const [personalInfo, setPersonalInfo] = useState({
    age: userOtherDetails.age || "",
    location: userOtherDetails.location || "",
    ethnicity: userOtherDetails.ethnicity || "",
  });
  const [appearanceInfo, setAppearanceInfo] = useState({
    height: userOtherDetails.height || "",
    weight: userOtherDetails.weight || "",
    hairColor: userOtherDetails.hairColor || "",
    eyeColor: userOtherDetails.eyeColor || "",
  });

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");

  const openCategoryPopup = () => setCategoryPopupVisible(true);
  const closeCategoryPopup = () => setCategoryPopupVisible(false);

  const openCompanyPopup = () => setCompanyPopupVisible(true);
const closeCompanyPopup = () => setCompanyPopupVisible(false);

const openLocationPopup = () => setLocationPopupVisible(true);
const closeLocationPopup = () => setLocationPopupVisible(false);

  // Handle the addition or editing of the "About" section content
  const handleAddAbout = async (newContent) => {
    try {
      let response;

      if (role === "director") {
        response = await updateDirectorAboutContent(userId, newContent);
        console.log("Director API Response:", response);
        if (response.success) {
          setAlertSeverity("success");
          setAlertMessage("Director's About section updated successfully.");
        } else {
          setAlertSeverity("error");
          setAlertMessage(
            response.message || "Failed to update Director's About section."
          );
        }
      } else if (role === "actor") {
        response = await updateAboutContent(userId, newContent);
        console.log("Actor API Response:", response);
        if (response.success) {
          setAlertSeverity("success");
          setAlertMessage("About section updated successfully.");
        } else {
          setAlertSeverity("error");
          setAlertMessage(
            response.message || "Failed to update About section."
          );
        }
      } else {
        console.error("Unexpected role value:", role);
        throw new Error("Invalid role");
      }

      setOpenSnackbar(true);
      setAboutContent(newContent); // Optionally update the local state
    } catch (error) {
      console.error("Error updating About section:", error);
      setAlertSeverity("error");
      setAlertMessage("Failed to update About section.");
      setOpenSnackbar(true);
    }
  };

  // Handle the addition or editing of the "Interested In" section content
  const handleAddInterest = async (newContent) => {
    try {
      const response = await updateInterestContent(userId, newContent);
      console.log("API Response:", response);
      if (response.success) {
        // Set alert for success
        setAlertSeverity("success");
        setAlertMessage("Interested In section updated successfully.");
        setOpenSnackbar(true);

        setInterestContent(newContent);
      } else {
        // Set alert for failure
        setAlertSeverity("error");
        setAlertMessage(
          response.message || "Failed to update Interested In section."
        );
        setOpenSnackbar(true);
      }
    } catch (error) {
      console.error("Error updating Interested In section:", error);

      setAlertSeverity("error");
      setAlertMessage("Failed to update Interested In section.");
      setOpenSnackbar(true);
    }
  };

  // Handle the addition or editing of the "Talents" section content
  const handleAddTalent = async (newContent) => {
    try {
      const response = await updateTalentsContent(userId, newContent);
      console.log("API Response:", response);
      if (response.success) {
        // Set alert for success
        setAlertSeverity("success");
        setAlertMessage("Talents section updated successfully.");
        setOpenSnackbar(true);
        setTalentContent(newContent);
      } else {
        // Set alert for failure
        setAlertSeverity("error");
        setAlertMessage(
          response.message || "Failed to update Talents section."
        );
        setOpenSnackbar(true);
      }
    } catch (error) {
      console.error("Error updating Talents section:", error);

      setAlertSeverity("error");
      setAlertMessage("Failed to update Talents section.");
      setOpenSnackbar(true);
    }
  };

  // Handle the addition or editing of the "Category" section content
  const handleAddCategory = async (newContent) => {
    try {
      const response = await updateDirectorCategoryContent(userId, newContent);
      console.log("API Response:", response);
      if (response.success) {
        setAlertSeverity("success");
        setAlertMessage("Category updated successfully.");
        setCategoryContent(newContent); // Update local state with new category
      } else {
        setAlertSeverity("error");
        setAlertMessage(response.message || "Failed to update category.");
      }
      setOpenSnackbar(true);
    } catch (error) {
      console.error("Error updating category:", error);
      setAlertSeverity("error");
      setAlertMessage("Failed to update category.");
      setOpenSnackbar(true);
    }
    closeCategoryPopup(); // Close the popup after handling
  };

  const handleUpdateCompany = async (newCompanyName) => {
    try {
      const response = await updateDirectorCompanyName(userId, newCompanyName);
      if (response.success) {
        setAlertSeverity("success");
        setAlertMessage("Company Name updated successfully.");
        setCompanyName(newCompanyName);
      } else {
        setAlertSeverity("error");
        setAlertMessage(response.message || "Failed to update Company Name.");
      }
      setOpenSnackbar(true);
    } catch (error) {
      setAlertSeverity("error");
      setAlertMessage("Failed to update Company Name.");
      setOpenSnackbar(true);
    }
    closeCompanyPopup();
  };
  
  const handleUpdateLocation = async (newLocation) => {
    try {
      const response = await updateDirectorLocation(userId, newLocation);
      if (response.success) {
        setAlertSeverity("success");
        setAlertMessage("Location updated successfully.");
        setLocation(newLocation);
      } else {
        setAlertSeverity("error");
        setAlertMessage(response.message || "Failed to update Location.");
      }
      setOpenSnackbar(true);
    } catch (error) {
      setAlertSeverity("error");
      setAlertMessage("Failed to update Location.");
      setOpenSnackbar(true);
    }
    closeLocationPopup();
  };
  


  // Handle the addition or editing of the "Hobbies" section content
  const handleAddHobbies = async (newContent) => {
    try {
      const response = await updateHobbiesContent(userId, newContent);
      console.log("API Response:", response);
      if (response.success) {
        // Set alert for success
        setAlertSeverity("success");
        setAlertMessage("Hobbies section updated successfully.");
        setOpenSnackbar(true);
        setHobbiesContent(newContent);
      } else {
        // Set alert for failure
        setAlertSeverity("error");
        setAlertMessage(
          response.message || "Failed to update Hobbies section."
        );
        setOpenSnackbar(true);
      }
    } catch (error) {
      console.error("Error updating Hobbies section:", error);

      setAlertSeverity("error");
      setAlertMessage("Failed to update Hobbies section.");
      setOpenSnackbar(true);
    }
  };

  // Handle the addition or editing of the "Personal" section content
  const handleUpdatePersonalInfo = async (updatedInfo) => {
    try {
      const [ageResponse, locationResponse, ethnicityResponse] =
        await Promise.all([
          updateAgeContent(userId, updatedInfo.age),
          updateLocationContent(userId, updatedInfo.location),
          updateEthnicityContent(userId, updatedInfo.ethnicity),
        ]);
      console.log("Age Update Response:", ageResponse);
      console.log("Location Update Response:", locationResponse);
      console.log("Ethnicity Update Response:", ethnicityResponse);
      if (
        ageResponse.success &&
        locationResponse.success &&
        ethnicityResponse.success
      ) {
        // Set alert for success
        setAlertSeverity("success");
        setAlertMessage("Personal information updated successfully.");
        setOpenSnackbar(true);

        setPersonalInfo(updatedInfo);
      } else {
        // Handle partial or full failure
        setAlertSeverity("error");
        setAlertMessage(
          "Failed to update one or more parts of the Personal section."
        );
        setOpenSnackbar(true);
      }
    } catch (error) {
      console.error("Error updating Personal section:", error);
      setAlertSeverity("error");
      setAlertMessage("Failed to update Personal information.");
      setOpenSnackbar(true);
    }
  };

  // Handle the addition or editing of the "Appearance" section content
  const handleUpdateAppearanceInfo = async (updatedInfo) => {
    try {
      const [
        heightResponse,
        weightResponse,
        hairColorResponse,
        eyeColorResponse,
      ] = await Promise.all([
        updateHeightContent(userId, updatedInfo.height),
        updateWeightContent(userId, updatedInfo.weight),
        updateHairColorContent(userId, updatedInfo.hairColor),
        updateEyeColorContent(userId, updatedInfo.eyeColor),
      ]);
      console.log("Height Update Response:", heightResponse);
      console.log("Weight Update Response:", weightResponse);
      console.log("Hair Color Update Response:", hairColorResponse);
      console.log("Eye Color Update Response:", eyeColorResponse);

      if (
        heightResponse.success &&
        weightResponse.success &&
        hairColorResponse.success &&
        eyeColorResponse.success
      ) {
        // Set alert for success
        setAlertSeverity("success");
        setAlertMessage("Appearance information updated successfully.");
        setOpenSnackbar(true);

        setAppearanceInfo(updatedInfo);
      } else {
        // Handle partial or full failure
        setAlertSeverity("error");
        setAlertMessage(
          "Failed to update one or more parts of the Appearance section."
        );
        setOpenSnackbar(true);
      }
    } catch (error) {
      console.error("Error updating Appearance section:", error);

      setAlertSeverity("error");
      setAlertMessage("Failed to update Appearance information.");
      setOpenSnackbar(true);
    }
  };

  // Functions to open and close the popups
  const openAboutPopup = () => setAboutPopupVisible(true);
  const closeAboutPopup = () => setAboutPopupVisible(false);
  const openInterestPopup = () => setInterestPopupVisible(true);
  const closeInterestPopup = () => setInterestPopupVisible(false);
  const openTalentPopup = () => setTalentPopupVisible(true);
  const closeTalentPopup = () => setTalentPopupVisible(false);
  const openHobbiesPopup = () => setHobbiesPopupVisible(true);
  const closeHobbiesPopup = () => setHobbiesPopupVisible(false);
  const openPersonalPopup = () => setPersonalPopupVisible(true);
  const closePersonalPopup = () => setPersonalPopupVisible(false);
  const openAppearancePopup = () => setAppearancePopupVisible(true);
  const closeAppearancePopup = () => setAppearancePopupVisible(false);

  return (
    <>
      <div className="log-profile-info">
        {/* ABOUT Section */}
        <div className="log-info-container">
          <div className="log-info-title">
            <span>ABOUT:</span>
            <FontAwesomeIcon
              icon={faEdit}
              onClick={openAboutPopup}
              className="editIcon"
            />
          </div>
          <div className="log-profile-details-box">
            <p>{aboutContent || "Introduce your Profile to the community..."}</p>
            {!aboutContent && (
              <span
                className="log-add-link"
                onClick={(e) => {
                  e.preventDefault();
                  openAboutPopup();
                }}
              >
                Add
              </span>
            )}
          </div>
        </div>

        <div className="log-info-row">
          {/* INTERESTED IN Section */}
          {userDetails.registerResult.role !== "director" && (
            <div className="log-info-container">
              <div className="log-info-title">
                <span>INTERESTED IN:</span>
                <FontAwesomeIcon
                  icon={faEdit}
                  onClick={openInterestPopup}
                  className="editIcon"
                />
              </div>
              <div className="log-profile-details-box">
                <p>{interestContent || "List the categories..."}</p>
                {!interestContent && (
                  <span
                    className="log-add-link"
                    onClick={(e) => {
                      e.preventDefault();
                      openInterestPopup();
                    }}
                  >
                    Add
                  </span>
                )}
              </div>
            </div>
          )}

          {/* TALENTS Section */}
          {userDetails.registerResult.role !== "director" && (
            <div className="log-info-container">
              <div className="log-info-title">
                <span>TALENTS:</span>
                <FontAwesomeIcon
                  icon={faEdit}
                  onClick={openTalentPopup}
                  className="editIcon"
                />
              </div>
              <div className="log-profile-details-box">
                <p>{talentContent || "List all the talents of your kid..."}</p>
                {!talentContent && (
                  <span
                    className="log-add-link"
                    onClick={(e) => {
                      e.preventDefault();
                      openTalentPopup();
                    }}
                  >
                    Add
                  </span>
                )}
              </div>
            </div>
          )}
        </div>

        {/* HOBBIES Section */}
        {userDetails.registerResult.role !== "director" && (
          <div className="log-info-container">
            <div className="log-info-title">
              <span>HOBBIES:</span>
              <FontAwesomeIcon
                icon={faEdit}
                onClick={openHobbiesPopup}
                className="editIcon"
              />
            </div>
            <div className="log-profile-details-box">
              <p>{hobbiesContent || "Add your child's hobbies here..."}</p>
              {!hobbiesContent && (
                <span
                  className="log-add-link"
                  onClick={(e) => {
                    e.preventDefault();
                    openHobbiesPopup();
                  }}
                >
                  Add
                </span>
              )}
            </div>
          </div>
        )}

        {/* PERSONAL Section */}
        {userDetails.registerResult.role !== "director" && (
          <div className="log-info-container">
            <div className="log-info-title">
              <span>PERSONAL:</span>
              <FontAwesomeIcon
                icon={faEdit}
                onClick={openPersonalPopup}
                className="editIcon"
              />
            </div>
            <div className="log-profile-details-box personal-info-row">
              <div className="personal-info-item">
                <p className="personal-info-label">Age:</p>
                <p>{personalInfo.age || "Add age"}</p>
              </div>
              <div className="personal-info-item">
                <p className="personal-info-label">Location:</p>
                <p>{personalInfo.location || "Add location"}</p>
              </div>
              <div className="personal-info-item">
                <p className="personal-info-label">Ethnicity:</p>
                <p>{personalInfo.ethnicity || "Add ethnicity"}</p>
              </div>
              <span
                className="log-add-link"
                onClick={(e) => {
                  e.preventDefault();
                  openPersonalPopup();
                }}
              >
                Edit
              </span>
            </div>
          </div>
        )}

        {/* CATEGORY Section */}
        {userDetails.registerResult.role === "director" && (
          <div className="log-info-container">
            <div className="log-info-title">
              <span>CATEGORY:</span>
              <FontAwesomeIcon
                icon={faEdit}
                onClick={openCategoryPopup}
                className="editIcon"
              />
            </div>
            <div className="log-profile-details-box">
              <p>{categoryContent || "Add category here..."}</p>
              {!categoryContent && (
                <span
                  className="log-add-link"
                  onClick={(e) => {
                    e.preventDefault();
                    openCategoryPopup();
                  }}
                >
                  Add
                </span>
              )}
            </div>
          </div>
        )}

        {/* COMPANY NAME Section */}
{userDetails.registerResult.role === 'director' && (
  <div className="log-info-container">
    <div className="log-info-title">
      <span>COMPANY NAME:</span>
      <FontAwesomeIcon
        icon={faEdit}
        onClick={openCompanyPopup}
        className="editIcon"
      />
    </div>
    <div className="log-profile-details-box">
      <p>{companyName || "Add company name here..."}</p>
      {!companyName && (
        <span
          className="log-add-link"
          onClick={(e) => {
            e.preventDefault();
            openCompanyPopup();
          }}
        >
          Add
        </span>
      )}
    </div>
  </div>
)}

{/* LOCATION Section */}
{userDetails.registerResult.role === 'director' && (
  <div className="log-info-container">
    <div className="log-info-title">
      <span>LOCATION:</span>
      <FontAwesomeIcon
        icon={faEdit}
        onClick={openLocationPopup}
        className="editIcon"
      />
    </div>
    <div className="log-profile-details-box">
      <p>{location || "Add location here..."}</p>
      {!location && (
        <span
          className="log-add-link"
          onClick={(e) => {
            e.preventDefault();
            openLocationPopup();
          }}
        >
          Add
        </span>
      )}
    </div>
  </div>
)}


        {/* APPEARANCE Section */}
        {userDetails.registerResult.role !== "director" && (
          <div className="log-info-container">
            <div className="log-info-title">
              <span>APPEARANCE:</span>
              <FontAwesomeIcon
                icon={faEdit}
                onClick={openAppearancePopup}
                className="editIcon"
              />
            </div>
            <div className="log-profile-details-box personal-info-row">
              <div className="personal-info-item">
                <p className="personal-info-label">Height:</p>
                <p>{appearanceInfo.height || "Add height"}</p>
              </div>
              <div className="personal-info-item">
                <p className="personal-info-label">Weight:</p>
                <p>{appearanceInfo.weight || "Add weight"}</p>
              </div>
              <div className="personal-info-item">
                <p className="personal-info-label">Hair Color:</p>
                <p>{appearanceInfo.hairColor || "Add hair color"}</p>
              </div>
              <div className="personal-info-item">
                <p className="personal-info-label">Eye Color:</p>
                <p>{appearanceInfo.eyeColor || "Add eye color"}</p>
              </div>
              <span
                className="log-add-link"
                onClick={(e) => {
                  e.preventDefault();
                  openAppearancePopup();
                }}
              >
                Edit
              </span>
            </div>
          </div>
        )}
      </div>

      {/* Popup Components */}
      {isAboutPopupVisible && (
        <AddPopup
          onClose={closeAboutPopup}
          onAdd={handleAddAbout}
          initialContent={aboutContent}
          title="Edit About Section"
          label="About"
        />
      )}
      {isCategoryPopupVisible && (
        <AddPopup
          onClose={closeCategoryPopup}
          onAdd={handleAddCategory}
          initialContent={categoryContent}
          title="Edit Category"
          label="Category"
        />
      )}
      {isInterestPopupVisible && (
        <AddPopup
          onClose={closeInterestPopup}
          onAdd={handleAddInterest}
          initialContent={interestContent}
          title="Edit Interested In Section"
          label="Interested In"
        />
      )}
      {isTalentPopupVisible && (
        <AddPopup
          onClose={closeTalentPopup}
          onAdd={handleAddTalent}
          initialContent={talentContent}
          title="Edit Talents Section"
          label="Talents"
        />
      )}
      {isHobbiesPopupVisible && (
        <AddPopup
          onClose={closeHobbiesPopup}
          onAdd={handleAddHobbies}
          initialContent={hobbiesContent}
          title="Edit Hobbies Section"
          label="Hobbies"
        />
      )}
      {isPersonalPopupVisible && (
        <EditPersonalPopup
          onClose={closePersonalPopup}
          onSave={handleUpdatePersonalInfo}
          initialInfo={personalInfo}
        />
      )}
      {isAppearancePopupVisible && (
        <EditAppearancePopup
          onClose={closeAppearancePopup}
          onSave={handleUpdateAppearanceInfo}
          initialInfo={appearanceInfo}
        />
      )}
     
{isCompanyPopupVisible && (
  <AddPopup
    onClose={closeCompanyPopup}
    onAdd={handleUpdateCompany}
    initialContent={companyName}
    title="Edit Company Name"
    label="Company Name"
  />
)}


{isLocationPopupVisible && (
  <AddPopup
    onClose={closeLocationPopup}
    onAdd={handleUpdateLocation}
    initialContent={location}
    title="Edit Location"
    label="Location"
  />
)}
      <Alert
        openSnackbar={openSnackbar}
        alertSeverity={alertSeverity}
        setOpenSnackbar={setOpenSnackbar}
        alertMessage={alertMessage}
      />
    </>
  );
};

export default ProfileInfo;
