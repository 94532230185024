import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  Box,
} from "@mui/material";
import {
  Close as CloseIcon,
  ArrowUpward as ArrowUpwardIcon,
} from "@mui/icons-material";
import termOfUseContent from "./termOfUseContent";
import "../style.css";

const TermOfUsePopup = ({ open, onClose }) => {
  const contentRef = React.useRef(null);

  const scrollToTop = () => {
    if (contentRef.current) {
      contentRef.current.scrollTop = 0;
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        Terms and Conditions
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        ref={contentRef}
        sx={{
          height: "90vh",
          overflowY: "auto",
          "&::-webkit-scrollbar": {
            width: "0.4em",
          },
          "&::-webkit-scrollbar-track": {
            boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "rgba(0,0,0,.1)",
            outline: "1px solid rgba(0,0,0,.1)",
            borderRadius: "10px",
          },
        }}
      >
        <Typography variant="body2" color="text.secondary" gutterBottom mb={3}>
          Last Updated: {termOfUseContent.lastUpdated}
        </Typography>

        {/* Section 1 */}
        <Box mb={3}>
          <Typography
            variant="h6"
            gutterBottom
            sx={{ textDecoration: "underline" }}
          >
            {termOfUseContent.section1.title}
          </Typography>
          <Typography
            paragraph
            dangerouslySetInnerHTML={{
              __html: termOfUseContent.section1.simpleSentence1,
            }}
          />
          <ol>
            {termOfUseContent.section1.numberedPoints.map((point, index) => (
              <li key={index}>
                <Typography dangerouslySetInnerHTML={{ __html: point }} />
              </li>
            ))}
          </ol>
          <Typography
            paragraph
            dangerouslySetInnerHTML={{
              __html: termOfUseContent.section1.simpleSentence2,
            }}
          />
        </Box>

        {/* Section 2 */}
        <Box mb={3}>
          <Typography
            variant="h6"
            gutterBottom
            sx={{ textDecoration: "underline" }}
          >
            {termOfUseContent.section2.title}
          </Typography>
          <Typography
            paragraph
            dangerouslySetInnerHTML={{
              __html: termOfUseContent.section2.paragraph1,
            }}
          />
          <Typography
            paragraph
            dangerouslySetInnerHTML={{
              __html: termOfUseContent.section2.paragraph2,
            }}
          />
          <Typography
            paragraph
            dangerouslySetInnerHTML={{
              __html: termOfUseContent.section2.paragraph3,
            }}
          />
          <ul>
            {termOfUseContent.section2.bulletPoints.map((point, index) => (
              <li key={index}>
                <Typography>{point}</Typography>
              </li>
            ))}
          </ul>

        </Box>

        {/* Section 3 */}
        <Box mb={3}>
          <Typography variant="h6" gutterBottom>
            {termOfUseContent.section3.title}
          </Typography>
          <Typography
            paragraph
            dangerouslySetInnerHTML={{
              __html: termOfUseContent.section3.paragraph1,
            }}
          />
          <Typography
            paragraph
            dangerouslySetInnerHTML={{
              __html: termOfUseContent.section3.paragraph2,
            }}
          />
          <Typography
            paragraph
            dangerouslySetInnerHTML={{
              __html: termOfUseContent.section3.paragraph3,
            }}
          />
        </Box>
      </DialogContent>
      <Box
        sx={{
          position: "sticky",
          bottom: 16,
          right: 16,
          textAlign: "right",
          pr: 2,
          pb: 2,
        }}
      >
        <IconButton onClick={scrollToTop} color="primary">
          <ArrowUpwardIcon />
        </IconButton>
      </Box>
    </Dialog>
  );
};

export default TermOfUsePopup;
