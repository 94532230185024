import React, { useState, useEffect } from "react";
import ActorCard from "./ActorCard";
import { actorData } from "../dumyData";
import SearchFilter from "../castingCalls/SearchFilter";
import Pagination from "@mui/material/Pagination";
import nprogress from "nprogress";
import "nprogress/nprogress.css";
import { getAllActors } from "../../api/axios";
import { Spinner } from "react-bootstrap";

const ActorsList = () => {
  const [searchInputs, setSearchInputs] = useState({
    name: "",
    country: "",
    zip: "",
    radius: "",
    age: "",
    gender: "",
  });
  const [isFiltered, setIsFiltered] = useState(false);
  const [page, setPage] = useState(1);
  const itemsPerPage = 30;
  const [pages, setPages] = useState(1);

  const [loading, setLoading] = useState(true);
  const [actorsList, setActorsList] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const handleChange = (event, value) => {
    setPage(value);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const GetAllActors = async () => {
    nprogress.start();
    try {
      const res = await getAllActors();
      if (res.success) {
        setActorsList(res.users);
      }
    } catch (error) {
      console.log("error fetching actors", error);
    } finally {
      nprogress.done();
      setLoading(false);
    }
  };

  useEffect(() => {
    GetAllActors();
  }, []);

  useEffect(() => {
    setFilteredData(actorsList);
  }, [actorsList]);

  useEffect(() => {
    setIsFiltered(Object.values(searchInputs).some((input) => input !== ""));
  }, [searchInputs]);

  const handleSearch = () => {
    let filtered = actorsList;

    if (searchInputs.name) {
      filtered = filtered.filter((item) =>
        item.user.firstName.toLowerCase().includes(searchInputs.name.toLowerCase())
      );
    }
    if (searchInputs.age) {
      filtered = filtered.filter((item) =>
        item.profil.age.toLowerCase().includes(searchInputs.age.toLowerCase())
      );
    }

    setFilteredData(filtered);
    setPage(1);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    const pageCount = Math.ceil(filteredData.length / itemsPerPage);
    setPages(pageCount || 1);
  }, [filteredData]);

  const handleResetFilters = () => {
    setSearchInputs({
      name: "",
      country: "",
      zip: "",
      radius: "",
      age: "",
      gender: "",
    });
    setFilteredData(actorsList);
    setPage(1);
  };

  const actorCount = filteredData?.length;
  const paginatedData = filteredData?.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  );
 

  if (loading) {
    return (
      <div className="spinner-page">
        <Spinner className="spinner" />
      </div>
    );
  }
  return (
    <div className="castingCall-list-full-wrapper">
      <div className="castingCall-list-conainer">
        <div className="castingCall-clear-filter">
          <div>
            <span className="all-role-count">{actorCount} Active Actors</span>
          </div>
          {isFiltered && (
            <div onClick={handleResetFilters} className="filter-clear">
              Reset filters
            </div>
          )}
        </div>
        <div className="seperate-line"></div>
        <div className="castingCall-grid">
          <div className="castingCall-list">
            <div className="actorList">
              {paginatedData.map((actor, index) => (
                <ActorCard key={index} actor={actor} />
              ))}
            </div>
            <div className="pagination">
              <Pagination
                count={pages}
                page={page}
                onChange={handleChange}
                boundaryCount={1}
                sx={{
                  "& .MuiPaginationItem-root": {
                    color: "#e54447",
                    "&:hover": {
                      backgroundColor: "rgba(229, 68, 71, 0.1)",
                    },
                  },
                  "& .MuiPaginationItem-root.Mui-selected": {
                    backgroundColor: "#e54447",
                    color: "#fff",
                    "&:hover": {
                      backgroundColor: "#d43d3f",
                    },
                  },
                  "& .MuiPaginationItem-ellipsis": {
                    color: "#e54447",
                    "&:hover": {
                      backgroundColor: "transparent",
                    },
                  },
                }}
              />
            </div>
          </div>
          <SearchFilter
            searchInputs={searchInputs}
            setSearchInputs={setSearchInputs}
            handleSearch={handleSearch}
          />
        </div>
      </div>
    </div>
  );
};

export default ActorsList;
