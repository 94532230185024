import React, { useState } from "react";
import "./Sidebar.css";

function Sidebar() {
  const [openSections, setOpenSections] = useState({
    overview: true,
    media: true,
    details: true,
  });

  const toggleSection = (section) => {
    setOpenSections((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };

  return (
    <div className="navigationSidebar">
      <ul>
        <li>
          <a
            href="#overview"
            className="overview"
            onClick={() => toggleSection("overview")}
          >
            OVERVIEW
            <span className={`arrow ${openSections.overview ? "up" : "down"}`}>
              ▼
            </span>
          </a>
          {openSections.overview && (
            <ul>
              <li>
                <a href="#interests">Interested In</a>
              </li>
              <li>
                <a href="#age">Age</a>
              </li>
              <li>
                <a href="#ethnicity">Ethnicity</a>
              </li>
              <li>
                <a href="#appearance">Appearance</a>
              </li>
              <li>
                <a href="#talents">Talents</a>
              </li>
              <li>
                <a href="#about">About</a>
              </li>
              <li>
                <a href="#hobbies">Hobbies</a>
              </li>
            </ul>
          )}
        </li>
        <li>
          <a
            href="#media"
            className="actorMedia"
            onClick={() => toggleSection("media")}
          >
            MEDIA
            <span className={`arrow ${openSections.media ? "up" : "down"}`}>
              ▼
            </span>
          </a>
          {openSections.media && (
            <ul>
              <li>
                <a href="#youtubeList">Demo Reel</a>
              </li>
              <li>
                <a href="#photoPreview">Photos</a>
              </li>
            </ul>
          )}
        </li>
        <li>
          <a
            href="#details"
            className="details"
            onClick={() => toggleSection("details")}
          >
            DETAILS
            <span className={`arrow ${openSections.details ? "up" : "down"}`}>
              ▼
            </span>
          </a>
          {openSections.details && (
            <ul>
              <li>
                <a href="#credits">Credits</a>
              </li>
            </ul>
          )}
        </li>
      </ul>
    </div>
  );
}

export default Sidebar;
