import React from "react";
import TextFieldWithValidation from "./TextFieldWithValidation";
import { Box } from "@mui/material";

const ActorForm = ({ formData, handleInputChange, handleBlur, errors }) => (
  <>
    <Box className="name-fields">
      <TextFieldWithValidation
        name="firstName"
        label="First Name"
        value={formData.firstName}
        onChange={handleInputChange}
        onBlur={handleBlur}
        error={errors.firstName}
      />
      <TextFieldWithValidation
        name="lastName"
        label="Last Name"
        value={formData.lastName}
        onChange={handleInputChange}
        onBlur={handleBlur}
        error={errors.lastName}
      />
    </Box>
    <Box className="name-fields">
      <TextFieldWithValidation
        name="zipCode"
        label="Zip Code"
        value={formData.zipCode}
        onChange={handleInputChange}
        onBlur={handleBlur}
        error={errors.zipCode}
      />
      <TextFieldWithValidation
        name="phoneNumber"
        label="Phone Number"
        value={formData.phoneNumber}
        onChange={handleInputChange}
        onBlur={handleBlur}
        error={errors.phoneNumber}
      />
    </Box>
    <TextFieldWithValidation
      name="email"
      label="Email"
      value={formData.email}
      onChange={handleInputChange}
      onBlur={handleBlur}
      error={errors.email}
    />
    <Box className="name-fields">
      <TextFieldWithValidation
        name="birthday"
        label="Birthday"
        type="date"
        value={formData.birthday}
        onChange={handleInputChange}
        onBlur={handleBlur}
        error={errors.birthday}
      />
      <TextFieldWithValidation
        sx={{
          width: "170px",
          "@media (max-width: 760px)": {
            width: "100%",
          },
        }}
        name="gender"
        label="Gender"
        value={formData.gender}
        onChange={handleInputChange}
        onBlur={handleBlur}
        error={errors.gender}
        select
        options={[
          { value: "male", label: "Male" },
          { value: "female", label: "Female" },
          { value: "trans", label: "Trans" },
          { value: "other", label: "Other" },
        ]}
      />
    </Box>
    <TextFieldWithValidation
      name="ethnicity"
      label="Ethnicity"
      value={formData.ethnicity}
      onChange={handleInputChange}
      onBlur={handleBlur}
      error={errors.ethnicity}
      select
      options={[
        { value: "african-american", label: "African-American" },
        { value: "latino/hispanic", label: "Latino / Hispanic" },
        { value: "white/caucasian", label: "White / Caucasian" },
        { value: "asian", label: "Asian" },
        { value: "middle_eastern", label: "Middle Eastern" },
        { value: "native_american", label: "Native American" },
        { value: "pacific_islander", label: "Pacific Islander" },
        { value: "indian/south_asian", label: "Indian/South Asian" },
        { value: "other", label: "Other" },
      ]}
    />
    <TextFieldWithValidation
      name="password"
      label="Create Password"
      type="password"
      value={formData.password}
      onChange={handleInputChange}
      onBlur={handleBlur}
      error={errors.password}
    />
    <TextFieldWithValidation
      name="confirmPassword"
      label="Confirm Password"
      type="password"
      value={formData.confirmPassword}
      onChange={handleInputChange}
      onBlur={handleBlur}
      error={errors.confirmPassword}
    />
  </>
);

export default ActorForm;
