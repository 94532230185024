import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVideoCamera } from "@fortawesome/free-solid-svg-icons";
import "./photopage.css";

const VideoPage = () => {
  const [headshot, setHeadshot] = useState(null);

  const handleImageChange = (event, setImage) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setImage(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  console.log("headshot", headshot);

  return (
    <div className="log-profile-video-info">
      <div className="log-video-title">Videos:</div>
      <div className="log-video-section">
        <div className="log-video-box-card">
          <div className="log-video-box">
            <input
              type="file"
              accept="video/*"
              style={{ display: "none" }}
              id="upload-demo-reel"
              onChange={(e) => handleImageChange(e, setHeadshot)}
            />
            <FontAwesomeIcon
              icon={faVideoCamera}
              className="log-video-upload-icon"
            />
            <label
              htmlFor="upload-demo-reel"
              className="headshot-upload-button"
            >
              Upload your demo reel
            </label>
          </div>
          <div className="video-label">Demo reel</div>
        </div>
        <div className="log-video-box-card">
          <div className="log-video-box">
            <input
              type="file"
              accept="video/*"
              style={{ display: "none" }}
              id="upload-demo-reel"
              onChange={(e) => handleImageChange(e, setHeadshot)}
            />
            <FontAwesomeIcon
              icon={faVideoCamera}
              className="log-video-upload-icon"
            />
            <label
              htmlFor="upload-demo-reel"
              className="headshot-upload-button"
            >
              Upload your slate
            </label>
          </div>
          <div className="video-label">Slate</div>
        </div>
        <div className="log-video-box-card">
          <div className="log-video-box">
            <input
              type="file"
              accept="video/*"
              style={{ display: "none" }}
              id="upload-demo-reel"
              onChange={(e) => handleImageChange(e, setHeadshot)}
            />
            <FontAwesomeIcon
              icon={faVideoCamera}
              className="log-video-upload-icon"
            />
            <label
              htmlFor="upload-demo-reel"
              className="headshot-upload-button"
            >
              Upload your UGC demo reel
            </label>
          </div>
          <div className="video-label">UGC demo reel</div>
        </div>
      </div>
    </div>
  );
};

export default VideoPage;
