import React from "react";
import ActorProfile1 from "../../components/Profilepages/Actor/ActorProfile1";
import Layout from "../../components/layout/Layout";

const ActorProfilePage = () => {
  return (
    <Layout>
      <div>
        <ActorProfile1 />
      </div>
    </Layout>
  );
};

export default ActorProfilePage;
