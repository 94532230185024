import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  Box,
} from "@mui/material";
import {
  Close as CloseIcon,
  ArrowUpward as ArrowUpwardIcon,
} from "@mui/icons-material";
import privacyPolicyContent from "./privacyPolicyContent";
import "../style.css";

const PrivacyPolicyPopup = ({ open, onClose }) => {
  const contentRef = React.useRef(null);

  const scrollToTop = () => {
    if (contentRef.current) {
      contentRef.current.scrollTop = 0;
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        Privacy Policy
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        ref={contentRef}
        sx={{
          height: "90vh",
          overflowY: "auto",
          "&::-webkit-scrollbar": {
            width: "0.4em",
          },
          "&::-webkit-scrollbar-track": {
            boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "rgba(0,0,0,.1)",
            outline: "1px solid rgba(0,0,0,.1)",
            borderRadius: "10px",
          },
        }}
      >
        <Typography variant="body2" color="text.secondary" gutterBottom mb={3}>
          Last Updated: {privacyPolicyContent.lastUpdated}
        </Typography>

        
        {/* Plain Language Privacy Policy */}
        <Box mb={3}>
          <Typography variant="h6" gutterBottom>
            {privacyPolicyContent.plainLanguagePolicy.title}
          </Typography>
          {privacyPolicyContent.plainLanguagePolicy.paragraphs.map(
            (paragraph, index) => (
              <Typography key={index} paragraph>
                {paragraph}
              </Typography>
            )
          )}
          {privacyPolicyContent.plainLanguagePolicy.bulletPoints.map(
            (point, index) => (
              <Box key={index} mb={2}>
                {typeof point === "string" ? (
                  <Typography paragraph>{point}</Typography>
                ) : (
                  <Box>
                    <Typography paragraph>{point.mainPoint}</Typography>
                    <ul style={{ paddingLeft: "20px" }}>
                      {point.subPoints.map((subPoint, subIndex) => (
                        <li key={subIndex}>
                          <Typography>{subPoint}</Typography>
                        </li>
                      ))}
                    </ul>
                  </Box>
                )}
              </Box>
            )
          )}
        </Box>

        {/*Refund Policy */}
        <Box mb={3}>
          <Typography variant="h6" gutterBottom>
            {privacyPolicyContent.refundPolicy.title}
          </Typography>
          <Typography paragraph>
            {privacyPolicyContent.refundPolicy.simpleSentence}
          </Typography>
        </Box>
      </DialogContent>
      <Box
        sx={{
          position: "sticky",
          bottom: 16,
          right: 16,
          textAlign: "right",
          pr: 2,
          pb: 2,
        }}
      >
        <IconButton onClick={scrollToTop} color="primary">
          <ArrowUpwardIcon />
        </IconButton>
      </Box>
    </Dialog>
  );
};

export default PrivacyPolicyPopup;
