import React, { useEffect, useState, useRef, useContext } from "react";
import "./castingCall.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { useParams, useNavigate } from "react-router-dom";
import nprogress from "nprogress";
import "nprogress/nprogress.css";
import { getCastingCallByID } from "../../api/axios";
import { Spinner } from "react-bootstrap";
import { AuthContext } from "../../context/AuthContext";

const CastingCallView = () => {
  const { id } = useParams();
  const { isAuthenticated, role } = useContext(AuthContext);
  const navigate = useNavigate();
  const [castingCall, setCastingCall] = useState({});
  const titleRef = useRef(null);
  const descriptionRef = useRef(null);
  const buttonRef = useRef(null);
  const rolesRef = useRef([]);
  const [loading, setLoading] = useState(true);
  const [applyButtonVisible, setApplyButtonVidible] = useState(true);

  const GetCastingCallByID = async (id) => {
    nprogress.start();
    try {
      const res = await getCastingCallByID(id);
      if (res.success) {
        const casting = res.casting;
        if (casting.roles && casting.roles.length > 0) {
          casting.roles = casting.roles.map((role) => {
            if (role.ethinicity) {
              role.ethinicity = JSON.parse(role.ethinicity);
            }
            return role;
          });
        }
        setCastingCall(casting);
      }
    } catch (error) {
      console.log("error fetching casting call", error);
    } finally {
      nprogress.done();
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      GetCastingCallByID(id);
    }
  }, [id]);

  useEffect(() => {
    rolesRef.current = new Array(castingCall.roles?.length).fill(null);
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    if (isAuthenticated && (role === "director" || role === "producer")) {
      setApplyButtonVidible(false);
    }
  }, [isAuthenticated, role]);

  const scrollToRef = (ref) => {
    if (ref) {
      const navbarHeight = 105;
      const topPosition =
        ref.getBoundingClientRect().top + window.scrollY - navbarHeight;
      window.scrollTo({ top: topPosition, behavior: "smooth" });
    }
  };

  const handleClickEditCC = (id) => {
    navigate(`/castingcalls/edit/${id}`);
  };

  const handleApply = () => {
    if (!isAuthenticated) {
      navigate(`/register`);
    }
    //api call for apply
  };

  if (loading) {
    return (
      <div className="spinner-page">
        <Spinner className="spinner" />
      </div>
    );
  }
  return (
    <div className="castingCall-view-full-wrapper">
      <div className="castingCall-view-container">
        <div className="castingCall-view-grid">
          <div className="castingCall-view-details">
            <span className="all-role-count" ref={titleRef}>
              {castingCall.title}
            </span>
            <div className="castingCall-view-location">
              <div className="castingCall-view-location-left">
                <span>{castingCall.zipCode}</span>
                <span>ID: {castingCall.callerId}</span>
                <span>
                  <FontAwesomeIcon icon={faCalendarAlt} />{" "}
                  {castingCall.expirationDate}
                </span>
              </div>
              {applyButtonVisible && (
                <button
                  className="castingCall-view-apply-button"
                  onClick={() => scrollToRef(buttonRef.current)}
                >
                  Apply
                </button>
              )}
              {(role === "director" || role === "producer") && (
                <button
                  className="castingCall-view-apply-button"
                  onClick={() => handleClickEditCC(id)}
                >
                  Edit
                </button>
              )}
            </div>
            <div className="seperate-line"></div>
            <div className="castingCall-view-location">
              <div className="castcall-categories">
                <span className="castcall-category">
                  {castingCall.category}
                </span>
                {castingCall.amount && (
                  <span className="title-right">
                    ${castingCall.amount}/{castingCall.period}
                  </span>
                )}
              </div>
              <span className="castcall-category">{castingCall.area}</span>
            </div>

            <div className="seperate-line"></div>
            <span className="castingCall-view-subTitle" ref={descriptionRef}>
              Description :
            </span>
            <div className="castingCall-view-des">{castingCall.shortDesc}</div>
            {castingCall.companyName && (
              <span className="castingCall-view-des">
                Company Title : {castingCall.companyName}
              </span>
            )}
            {castingCall.contactNo && (
              <span className="castingCall-view-des">
                Company number : {castingCall.contactNo}
              </span>
            )}
            {castingCall.websiteURL && (
              <span className="castingCall-view-des">
                Company url :{" "}
                <a
                  href={castingCall.websiteURL}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="link"
                >
                  {castingCall.websiteURL}
                </a>
              </span>
            )}
            <div className="seperate-line"></div>
            <span className="all-role-count" ref={buttonRef}>
              {castingCall.roles?.length}{" "}
              {castingCall.roles?.length === 1 ? "role" : "roles"}
            </span>
            {castingCall.roles?.map((item, index) => (
              <div
                className="castingCall-view-role-container"
                key={item.id}
                ref={(el) => (rolesRef.current[index] = el)}
              >
                <span className="castingCall-view-subTitle">{item.title}</span>
                <div className="castingCall-view-des">
                  <span>{item.gender} </span>
                  <span>
                    {item.ageMin}-{item.ageMax} y.o
                  </span>
                </div>
                <span className="castingCall-view-des">
                  {item.ethinicity?.map((item, index) => (
                    <span key={index}>{item}, </span>
                  ))}
                </span>
                <span className="castingCall-view-des">{item.description}</span>
                {applyButtonVisible && (
                  <button
                    className="castingCall-role-apply-button"
                    onClick={handleApply}
                  >
                    Apply
                  </button>
                )}
              </div>
            ))}
          </div>
          <div className="castingCall-view-ref-container">
            <span className="castingCall-view-ref-title">Overview</span>
            <div className="castingCall-ref-list">
              <span
                className="castingCall-ref"
                onClick={() => scrollToRef(titleRef.current)}
              >
                Intro
              </span>
              <span
                className="castingCall-ref"
                onClick={() => scrollToRef(descriptionRef.current)}
              >
                Description
              </span>
            </div>
            <span className="castingCall-view-ref-title">
              Roles ({castingCall.roles?.length})
            </span>
            <div className="castingCall-ref-list">
              {castingCall.roles?.map((item, index) => (
                <span
                  className="castingCall-ref"
                  key={item.id}
                  onClick={() => scrollToRef(rolesRef.current[index])}
                >
                  {item.title}
                </span>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CastingCallView;
