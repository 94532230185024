import React from "react";
import YouTubeCard from "./YoutubeCard";
import "./actorDetails.css";

function YoutubeList() {
  return (
    <div className="youtube-content">
      <h1>Assets</h1>
      <br />
      <h2>Videos</h2> <br />
      <div className="youtube-grid">
        <YouTubeCard
          videoId="sGa6zzTgutE"
          thumbnailUrl="https://img.youtube.com/vi/sGa6zzTgutE/hqdefault.jpg"
        />
        <YouTubeCard
          videoId="rQQp9FkVJgI"
          thumbnailUrl="https://img.youtube.com/vi/rQQp9FkVJgI/hqdefault.jpg"
        />
        <YouTubeCard
          videoId="8mdCTWoQRo0"
          thumbnailUrl="https://img.youtube.com/vi/8mdCTWoQRo0/hqdefault.jpg"
        />
        <YouTubeCard
          videoId="sGa6zzTgutE&t"
          thumbnailUrl="https://img.youtube.com/vi/sGa6zzTgutE/hqdefault.jpg"
        />
      </div>
    </div>
  );
}

export default YoutubeList;
