import React, { useState } from 'react';
import PlanOption from './PlanOption';
import './subscriptionPlans.css';

const SubscriptionPlans = () => {
  const plansData = [
    {
      id: 1,
      duration: "12 Months",
      originalPrice: 35.99,
      price: 99,
      savingsPercentage: 86,
      hasGuarantee: true,
      isBestDeal: true
    },
    {
      id: 3,
      duration: "A Casting Call",
      originalPrice: 35.99,
      price: 9.99,
      savingsPercentage: 33,
      hasGuarantee: false,
      isBestDeal: false
    }
  ];

  const [selectedPlan, setSelectedPlan] = useState(plansData[0].id);

  const handlePlanSelect = (planId) => {
    setSelectedPlan(planId);
  };

  const handleSubscribe = () => {
    console.log(`Subscribing to plan: ${selectedPlan}`);
  };

  return (
    <div className='subscriptions-container' >
        <div className='subscription-header'>
            <h1 className='subscription-title'>Select a Plan To Subscribe</h1>
            <p className='subscription-subtitle'>Get Discovered by Top Industry Professionals</p>
        </div>
        <div className="subscription-container">
           <div className="plans">
        {plansData.map((plan) => (
          <PlanOption
            key={plan.id}
            plan={plan}
            isSelected={selectedPlan === plan.id}
            onSelect={() => handlePlanSelect(plan.id)}
          />
        ))}
      </div>
      <button className="subscribe-btn" onClick={handleSubscribe}>
        Subscribe Now
      </button> 
        </div>

      
    </div>
  );
};

export default SubscriptionPlans;