const termOfUseContent = {
    lastUpdated: "August 27, 2024",
    section1: {
      title: "Accepting the Term",
      simpleSentence1: "In order for you to use the <strong>Open Curtains Casting</strong> Sites, you must first accept these Terms. You can accept these Terms by:",
      numberedPoints:[
        "On the <strong>Open Curtains Casting</strong> Site, or any part of the <strong>Open Curtains Casting</strong> Site, that requires registration, signing in or checking a box near a statement that indicates your agreement with or acceptance of the Terms.",
        "Using any of the <strong>Open Curtains Casting</strong> Site that link to these Terms, in which case you understand and agree that these Terms will apply to your use of those Sites (or any parts of them), in which case, if you do not agree, you should immediately discontinue your use of the Sites.",
      ],
      simpleSentence2:"<strong>You may wish to print or save a copy of the Terms for your records.</strong>",
    },
    section2: {
      title: "Intellectual Property Claim",
      paragraph1: "All of the information and content displayed on the <strong>Open Curtains Casting</strong> Site including, without limitation, text, graphics, photographs, images, moving images, sound, and illustrations (Content), may be subject to use by <strong>Open Curtains Casting</strong>, its licensors, agents, and/or Content providers. All Content on the <strong>Open Curtains Casting</strong> Sites.",
      paragraph2: "The <strong>Open Curtains Casting</strong> Site, the Content and all related rights shall remain the exclusive property of <strong>Open Curtains Casting</strong>, its licensors, agents and/or Content providers, unless otherwise expressly agreed. You acknowledge that you do not acquire any proprietary rights by copying or downloading any Content that is copyrighted or protected by any other intellectual property right. You agree that you will not remove any copyright, trademark, or other proprietary notices from material found on the <strong>Open Curtains Casting</strong> Site.",
      paragraph3: "If you believe that content on the <strong>Open Curtains Casting</strong> Site violates other non-copyright intellectual property rights, including your trademark or publicity rights, please promptly notify Open Curtains Casting by submitting the following information to the street or email address set forth below: the basis for your rights (including any trademark or other relevant registrations); a succinct description of the alleged violation and the nature of the rights violated; and the location (including URL or internet address) of the content that you believe to be infringing on the Sites.",
      bulletPoints:[
        "By Mail: Open Curtains Casting, LLC Post Office Box 3225 Rock Hill, SC 29732.",
        "By Email: Legal@opencurtainscasting.com"
      ]
    },
    section3: {
      title: "Restrictions on Use of the Sites",
      paragraph1: "You agree that you will not post pornographic material on the Open Curtains Casting Site, or otherwise use the <strong>Open Curtains Casting</strong> Site or Content in any manner, that could: (i) humiliate, threaten, defame, harass, or injure other people or their property rights, including, but not limited to, intellectual property rights; (ii) violate the privacy or publicity rights of other individuals or entities; (iii) be considered criminal conduct or give rise to civil liability; or (iv) otherwise violate any law or regulation or these Terms. You further understand and agree that posting unsolicited advertisements on the Open Curtains Casting Site is expressly prohibited by these Terms.",
      paragraph2: "You agree that you will not use any robot, spider, scraper or other automated means to access the <strong>Open Curtains Casting</strong> Site for any purpose without our express written permission. Additionally, you agree that you will not: (i) take any action that imposes, or may impose in our sole discretion an unreasonable or disproportionately large load on our infrastructure; (ii) interfere or attempt to interfere with the proper working of the site or any activities conducted on the <strong>Open Curtains Casting Site</strong> ; or (iii) bypass any measures we may use to prevent or restrict access to the <strong>Open Curtains Casting Site</strong>. ",
      paragraph3: "Any unauthorized use of the <strong>Open Curtains Casting</strong> Site or computer systems is a violation of these Terms. In addition to any remedies that we may have at law or in equity, if we determine, in our sole discretion, that you have violated or are likely to violate the foregoing prohibitions, we may take any action we deem necessary to prevent or cure the violation, including without limitation, the immediate termination of your access to the <strong>Open Curtains Casting Site</strong>",
    }
  };
  
  export default termOfUseContent;
  