import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./Addpopup.css";

const AddPopup = ({ title, initialContent = "", onAdd, onClose }) => {
  const [content, setContent] = useState(initialContent);

  useEffect(() => {
    setContent(initialContent);
  }, [initialContent]);

  const handleSubmit = () => {
    if (typeof onAdd === "function") {
      onAdd(content);
      onClose();
    } else {
      console.error("onAdd is not a function");
    }
  };

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <h2>{title}</h2>
        <textarea
          value={content}
          onChange={(e) => setContent(e.target.value)}
          rows="4"
          placeholder="Enter content here..."
        />
        <div className="edit-popup-footer">
          <button className="save-button" onClick={handleSubmit}>
            Save
          </button>
          <button className="cancel-button" onClick={onClose}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

AddPopup.propTypes = {
  title: PropTypes.string.isRequired,
  initialContent: PropTypes.string,
  onAdd: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default AddPopup;
