import React from "react";
import Layout from "../../components/layout/Layout";
import Registration from "./Registration";

const RegistrationForm = () => {
  return (
    <Layout>
      <div>
        <Registration />
      </div>
    </Layout>
  );
};

export default RegistrationForm;