import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import React from "react";
import "./common.css";
import castImage1 from "../../assets/cast1.jpg";
import castImage2 from "../../assets/cast2.jpg";
import castImage3 from "../../assets/cast3.jpg";
import castImage4 from "../../assets/cast4.jpg";
import castImage5 from "../../assets/cast5.jpg";

const SwiperComponent = () => {
  return (
    <div className="carousel-wrapper">
      <div className="carousel1">
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          navigation
          autoplay={{
            delay: 4000,
            disableOnInteraction: false,
          }}
          speed={2000}
          modules={[Navigation, Pagination, Autoplay]}
        >
          <SwiperSlide>
            <div className="slide-container">
              <div className="slides-wrapper">
                <div className="slide-image-div">
                  <img src={castImage2} alt="" className="slide-image" />
                </div>
                <div className="slide-image-div">
                  <img src={castImage3} alt="" className="slide-image" />
                </div>
                <div className="slide-image-div">
                  <img src={castImage5} alt="" className="slide-image" />
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="slide-container">
              <div className="slides-wrapper">
                <div className="slide-image-div">
                  <img src={castImage1} alt="" className="slide-image" />
                </div>
                <div className="slide-image-div">
                  <img src={castImage3} alt="" className="slide-image" />
                </div>
                <div className="slide-image-div">
                  <img src={castImage4} alt="" className="slide-image" />
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
      <div className="carousel2">
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          navigation
          autoplay={{
            delay: 4000,
            disableOnInteraction: false,
          }}
          speed={2000}
          modules={[Navigation, Pagination, Autoplay]}
        >
          <SwiperSlide>
            <div className="slide-container">
              <div className="slide-image-div">
                <img src={castImage2} alt="" className="slide-image" />
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="slide-container">
              <div className="slide-image-div">
                <img src={castImage3} alt="" className="slide-image" />
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="slide-container">
              <div className="slide-image-div">
                <img src={castImage5} alt="" className="slide-image" />
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="slide-container">
              <div className="slide-image-div">
                <img src={castImage1} alt="" className="slide-image" />
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="slide-container">
              <div className="slide-image-div">
                <img src={castImage3} alt="" className="slide-image" />
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="slide-container">
              <div className="slide-image-div">
                <img src={castImage4} alt="" className="slide-image" />
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
};

export default SwiperComponent;
