import React from "react";
import "./castingDirector.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { workFlow } from "../dumyData";
import { useNavigate } from "react-router-dom";

const HowWork = () => {
  const navigate = useNavigate();

  const handleRegisterClick = () => {
    navigate("/register");
  };

  return (
    <div className="director-work-full-wrapper">
      <div className="director-work-wrapper">
        <div className="director-work-details">
          <div className="director-top-title">
            How Open Curtains Casting Works
          </div>
          <span className="director-work-subTitle">
            Open Curtains Casting is a comprehensive platform designed to
            connect young talent with casting opportunities across the
            entertainment industry
          </span>
          <div className="director-work-process">
            {workFlow?.map((item, index) => (
              <div className="director-workProcess-card" key={index}>
                <span className="director-work-icon">
                  <FontAwesomeIcon icon={item.icon} />
                </span>
                <div className="director-workProcess-card-text">
                  <span className="director-card-title">{item.title}</span>
                  <span className="director-card-des">{item.des}</span>
                </div>
              </div>
            ))}
          </div>
          <button className="homeMiddle2" onClick={handleRegisterClick}>
            Post Your Casting Call
          </button>
        </div>
      </div>
    </div>
  );
};

export default HowWork;
