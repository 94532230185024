export const validateField = (name, value, formData, tabValue, touchedFields) => {
  const newErrors = {};

  if (!formData) {
    console.error('Form data is undefined');
    return;
  }

  // if (!touchedFields[name]) return newErrors;

  switch (name) {
    case "firstName":
      newErrors.firstName = !value ? "First name is required" : "";
      break;
    case "lastName":
      newErrors.lastName = !value ? "Last name is required" : "";
      break;
    case "zipCode":
      newErrors.zipCode = !value
        ? "Zip code is required"
        : isNaN(value)
        ? "Zip code must be a number"
        : "";
      break;
    case "email":
      newErrors.email = !value
        ? "Email is required"
        : !/\S+@\S+\.\S+/.test(value)
        ? "Email address is invalid"
        : "";
      break;
    case "phoneNumber":
      newErrors.phoneNumber = !value
        ? "Phone number is required"
        : isNaN(value)
        ? "Phone number must be a number"
        : "";
      break;
    case "gender":
      if (tabValue === 0) {
        newErrors.gender = !value ? "Gender is required" : "";
      }
      break;
    case "birthday":
      if (tabValue === 0) {
        newErrors.birthday = !value
          ? "Birthday is required"
          : isNaN(Date.parse(value))
          ? "Invalid date format"
          : new Date(value) > new Date()
          ? "Birthday cannot be in the future"
          : "";
      }
      break;
    case "ethnicity":
      if (tabValue === 0) {
        newErrors.ethnicity = !value ? "Ethnicity is required" : "";
      }
      break;
    case "selectedRole":
      if (tabValue === 1) {
        newErrors.selectedRole = !value ? "This field is required" : "";
      }
      break;
    case "age":
      if (tabValue === 2) {
        newErrors.age = !value ? "Age is required" : "";
      }
      break;
    case "password":
      newErrors.password = !value
        ? "Please enter your password"
        : !/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/.test(
            value
          )
        ? "Password must include at least 8 characters, a symbol, a capital letter, a simple letter, and a number"
        : "";
      break;
    case "confirmPassword":
      newErrors.confirmPassword = !value
        ? "Confirm password is required"
        : value !== formData.password
        ? "Passwords do not match"
        : "";
      break;
    case "checkBox":
      newErrors.checkBox = !value
        ? "You must agree to the terms and conditions"
        : "";
      break;
    default:
      break;
  }

  return newErrors;
};

export const validateForm = (formData, tabValue, touchedFields) => {
  const errors = {};

  const fieldsToValidate = [
    "firstName",
    "lastName",
    "zipCode",
    "email",
    "phoneNumber",
    "password",
    "confirmPassword",
    "agreement",
  ];

  if (tabValue === 0) {
    fieldsToValidate.push("gender", "birthday", "ethnicity");
  } else if (tabValue === 1) {
    fieldsToValidate.push("selectedRole");
  } else if (tabValue === 2) {
    fieldsToValidate.push("age");
  }

  fieldsToValidate.forEach((field) => {
    const fieldErrors = validateField(field, formData[field], formData, tabValue);
    if (fieldErrors[field]) {
      errors[field] = fieldErrors[field];
    }
  });

  return errors;
};



export const checkAllRequiredFieldsFilled = (formData, tabValue) => {
  const requiredFields = [
    "firstName",
    "lastName",
    "zipCode",
    "email",
    "phoneNumber",
    "password",
    "confirmPassword",
    "agreement",
  ];

  if (tabValue === 0) {
    requiredFields.push("gender", "birthday", "ethnicity");
  } else if (tabValue === 1) {
    requiredFields.push("selectedRole");
  } else if (tabValue === 2) {
    requiredFields.push("age");
  }

  return requiredFields.every((field) => {
    if (typeof formData[field] === "boolean") {
      return formData[field] === true;
    }
    return formData[field]?.trim() !== "";
  });
};

