import React, { useState } from "react";
import "./actorDetails.css";

const YouTubeCard = ({ videoId, thumbnailUrl }) => {
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlayClick = () => {
    setIsPlaying(true);
  };

  return (
    <div className="youtube-card">
      {!isPlaying ? (
        <div className="thumbnail-container">
          <img
            src={thumbnailUrl}
            alt="YouTube Video Thumbnail"
            className="thumbnail"
          />
          <button onClick={handlePlayClick} className="play-button">
            ▶
          </button>
        </div>
      ) : (
        <iframe
          src={`https://www.youtube.com/embed/${videoId}?autoplay=1`}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          className="iframe"
        />
      )}
    </div>
  );
};

export default YouTubeCard;
