import React from "react";
import Layout from "../../components/layout/Layout";
import CastingCallList from "../../components/castingCalls/CastingCallList";
import CastingCallSlide from "../../components/castingCalls/castingCallSlide";

const CastingCalls = () => {
  return (
    <Layout>
      <div>
        <CastingCallSlide />
        <CastingCallList />
      </div>
    </Layout>
  );
};

export default CastingCalls;
