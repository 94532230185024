import React from "react";
import DirectorProfilePage1 from "../../../components/Profilepages/Diector/DirectorProfilePage1";
import ProfileLayout from "../../../components/layout/ProfileLayout";

const DirectorProfilePage = () => {
  return (
    <ProfileLayout>
      <div>
        <DirectorProfilePage1/>
      </div>
    </ProfileLayout>
  );
};

export default DirectorProfilePage;
