const privacyPolicyContent = {
  lastUpdated: "August 27, 2024",
  plainLanguagePolicy: {
    title: "Plain Language Privacy Policy for Open Curtains Casting, LLC",
    paragraphs: [
      "Open Curtains Casting prides itself on providing a valuable service for its Users. We want every User to feel like a valued member of our artistic community, and we respect the privacy of each User and work to guard and protect that privacy as we do our own. Accordingly, the Privacy Policy that governs your relationship with Open Curtains Casting as part and parcel of the Terms of Use (incorporated therein by reference as if printed verbatim in the Terms of Use) is printed in plain English as follows:",
    ],
    bulletPoints: [
      {
        mainPoint: "Open Curtains Casting collects personal information, including but not limited to the following data, regarding each User on the system:",
        subPoints: [
          "Name",
          "Address",
          "Email address",
          "Telephone Number",
          "Payment Information (credit card numbers and other pertinent information to facilitate authorization(s) for payment of the User’s account fees, debit card numbers and other pertinent information to facilitate authorization(s) for payment of the User’s account fees, and other sources of payment for the User’s account)",
          "Information pertaining to the User’s interactions with the Open Curtain Casting system, the pictures and videos uploaded, communications between Open Curtain Casting and the User (and/or included third parties)",
          "Information regarding the User’s job placement eligibility and other results from system use.",
        ],
      },
      "Open Curtains Casting collects, monitors, and utilizes the User’s information for its internal purposes regarding the Open Curtains Casting system and/or services.",
      "Open Curtains Casting does not share User’s personal data with third parties, except as required by law for law enforcement purposes, for compliance with court orders, and/or for other similar circumstances.",
      "If and/or when Open Curtains Casting elects to allow advertisers and/or other potential partners to access and/or advertise and/or otherwise interact with the system, Open Curtains Casting will notify each active User via the then current email address on file for that User and will post an updated copy of the privacy policies on this website.",
      "Open Curtains Casting does utilize cookies to monitor Users’ interactions with the system (e.g., visiting certain pages) in order to tailor the services to better serve Open Curtains Casting’s customers. Users may elect to choose certain cookie uses and offerings and decline others (“opt out”) on the appropriate page of this website. PMF will notify active Users of any future changes to PMF’s cookie policies via email message to the then current email address on file for that User and will post an updated copy of the privacy and/or cookie policies as relevant to the change in cookie policy on this website.",
      "We retain your personal information for as long as necessary to fulfill our obligations to the User and to potentially impact third parties if you are selected for projects.",
      {
        mainPoint: "You may contact us with any questions regarding this Privacy Policy in writing at the following address:",
        subPoints: [
          "Open Curtains Casting, LLC Post Office Box 3225 Rock Hill, SC 29732.",
          "You may also contact us via email using the address, help@opencurtainscasting.com and entering 'Privacy Policy Concern' on the subject line.",
        ],
      },
      "We look forward to working with you and hope you enjoy an awesome experience with Open Curtains Casting!",
    ],
  },

  refundPolicy: {
    title: "Refund Policy",
    simpleSentence:
      "As stated in our Terms of Service, refunds are nonrefundable. If a user chooses not to be charged recurring fees, the subscription must be canceled before the next billing cycle.",
  },
};

export default privacyPolicyContent;
