import React, { useState } from "react";
import "./castingCall.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Modal from "@mui/material/Modal";
import {
  TextField,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Typography,
} from "@mui/material";

const RoleAddModal = ({ openRoleModal, setOpenRoleModal, handleAddRole }) => {
  const ethnicityOptions = [
    "African-American",
    "Middle Eastern",
    "Latino/ Hispanic",
    "Native American",
    "White/ Caucasian",
    "Pacific Islander",
    "Others",
    "Indian/ South Asian",
    "Asian",
  ];
  const initialValue = {
    title: "",
    desc: "",
    gender: "",
    ageMin: "",
    ageMax: "",
    ethinicity: [],
    castingRole: "actor",
  };
  const [formData, setFormData] = useState(initialValue);
  const [errors, setErrors] = useState({});

  //close modal
  const handleClose = () => {
    setOpenRoleModal(!openRoleModal);
    setFormData(initialValue);
    setErrors({});
  };

  //save changed inputs
  const handleInputChange = (name, value) => {
    if (name === "ethinicity") {
      setFormData((prevDetails) => {
        const newEthnicities = prevDetails.ethinicity.includes(value)
          ? prevDetails.ethinicity.filter((ethnicity) => ethnicity !== value)
          : [...prevDetails.ethinicity, value];

        return {
          ...prevDetails,
          ethinicity: newEthnicities,
        };
      });
    } else {
      setFormData((prevDetails) => ({
        ...prevDetails,
        [name]: value,
      }));
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  //validate form
  const validateForm = () => {
    const errors = {};

    if (!formData.title) {
      errors.title = "Please add role title";
    }
    if (!formData.desc) {
      errors.desc = "Please add small description";
    }
    if (!formData.gender) {
      errors.gender = "Please select gender";
    }
    if (!formData.ageMin) {
      errors.ageMin = "Please add min age";
    }
    if (!formData.ageMax) {
      errors.ageMax = "Please add max age";
    }
    if (formData.ageMin && formData.ageMax) {
      const ageMin = Number(formData.ageMin);
      const ageMax = Number(formData.ageMax);
      if (ageMax < ageMin) {
        errors.ageMax = "The maximum age must be greater than the minimum age";
      }
    }
    if (formData.ethinicity.length === 0) {
      errors.ethinicity = "Please choose atleast one ethnicity";
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  //add a role
  const handleSubmit = () => {
    if (validateForm()) {
      handleAddRole(formData);
      handleClose();
    }
    console.log(formData);
  };

  return (
    <Modal
      open={openRoleModal}
      onClose={handleClose}
      sx={{ overflowY: "auto" }}
    >
      <div className="role-add-modal-wrapper">
        <div className="role-add-modal-container">
          <div className="role-add-modal-title">
            <span className="all-role-count">Add a new role</span>
            <div className="modal-close-icon" onClick={handleClose}>
              <FontAwesomeIcon icon={faTimes} />{" "}
            </div>
          </div>
          <TextField
            label="Role Title"
            name="title"
            value={formData.title}
            onChange={(e) => handleInputChange("title", e.target.value)}
            variant="filled"
            fullWidth
            sx={{
              backgroundColor: "transparent",
              "& .MuiFilledInput-root": {
                backgroundColor: "transparent",
              },
            }}
            error={!!errors.title}
            helperText={errors.title}
          />
          <TextField
            label="Write a short description"
            name="desc"
            value={formData.desc}
            onChange={(e) => handleInputChange("desc", e.target.value)}
            variant="filled"
            fullWidth
            sx={{
              backgroundColor: "transparent",
              "& .MuiFilledInput-root": {
                backgroundColor: "transparent",
              },
              mt: "10px",
            }}
            error={!!errors.desc}
            helperText={errors.desc}
          />
          <FormControl
            fullWidth
            variant="filled"
            sx={{
              backgroundColor: "transparent",
              "& .MuiFilledInput-root": {
                backgroundColor: "transparent",
              },
              mt: "10px",
            }}
          >
            <InputLabel>Gender</InputLabel>
            <Select
              value={formData.gender}
              onChange={(e) => handleInputChange("gender", e.target.value)}
              name="gender"
              MenuProps={{
                disableScrollLock: true,
              }}
            >
              <MenuItem value="boy">Boy</MenuItem>
              <MenuItem value="girl">Girl</MenuItem>
              <MenuItem value="man">Man</MenuItem>
              <MenuItem value="woman">Woman</MenuItem>
            </Select>
            {errors.gender && (
              <Typography variant="caption" color="error" sx={{ ml: "15px" }}>
                {errors.gender}
              </Typography>
            )}
          </FormControl>
          <div className="grid-inputs">
            <TextField
              label="Min Age"
              name="ageMin"
              type="number"
              value={formData.ageMin}
              onChange={(e) => handleInputChange("ageMin", e.target.value)}
              variant="filled"
              fullWidth
              sx={{
                backgroundColor: "transparent",
                "& .MuiFilledInput-root": {
                  backgroundColor: "transparent",
                },
                "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
                  {
                    display: "none",
                  },
                mt: "10px",
              }}
              onKeyDown={(e) => {
                if (e.key === "-" || e.key === "e" || e.key === "E") {
                  e.preventDefault();
                }
              }}
              error={!!errors.ageMin}
              helperText={errors.ageMin}
            />
            <TextField
              label="Max Age"
              name="ageMax"
              type="number"
              value={formData.ageMax}
              onChange={(e) => handleInputChange("ageMax", e.target.value)}
              variant="filled"
              fullWidth
              sx={{
                backgroundColor: "transparent",
                "& .MuiFilledInput-root": {
                  backgroundColor: "transparent",
                },
                "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
                  {
                    display: "none",
                  },
                mt: "10px",
              }}
              onKeyDown={(e) => {
                if (e.key === "-" || e.key === "e" || e.key === "E") {
                  e.preventDefault();
                }
              }}
              error={!!errors.ageMax}
              helperText={errors.ageMax}
            />
          </div>
          <div className="ethnicity-topic">
            <span className="castingCall-create-section-topic">
              Ethnicities(choose atleast one)
            </span>
            {errors.ethinicity && (
              <Typography variant="caption" color="error">
                {errors.ethinicity}
              </Typography>
            )}
          </div>
          <div className="checkbox-inputs">
            {ethnicityOptions.map((ethnicityItem) => (
              <FormControlLabel
                key={ethnicityItem}
                control={
                  <Checkbox
                    checked={formData.ethinicity.includes(ethnicityItem)}
                    onChange={(e) =>
                      handleInputChange("ethinicity", ethnicityItem)
                    }
                    inputProps={{ "aria-label": ethnicityItem }}
                  />
                }
                label={ethnicityItem}
                sx={{ color: "gray" }}
              />
            ))}
          </div>
          <div>
            <button className="review-submit-button" onClick={handleSubmit}>
              Add
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default RoleAddModal;
