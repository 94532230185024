import React from "react";
import SwiperComponent from "../common/carousel";

const CastingCallSlide = () => {
  return (
    <div className="castingCall-list-full-wrapper">
      <div className="castingCall-list-conainer">
        <SwiperComponent />
      </div>
    </div>
  );
};

export default CastingCallSlide;
