import React from "react";
import { TextField, MenuItem, InputAdornment, IconButton } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const TextFieldWithValidation = ({
  name,
  label,
  value,
  onChange,
  onBlur,
  error,
  type = "text",
  select = false,
  options = [],
  ...props
}) => {
  const [showPassword, setShowPassword] = React.useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div>
      {select ? (
        <TextField
          select
          name={name}
          label={label}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          error={!!error}
          helperText={error}
          fullWidth
          margin="normal"
          size="small"
          InputLabelProps={{
            style: { fontSize: 'inherit' }
          }}
          InputProps={{
            style: { fontSize: 'inherit' }
          }}
          {...props}
        >
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      ) : (
        <TextField
          name={name}
          label={label}
          type={
            type === "password" ? (showPassword ? "text" : "password") : type
          }
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          error={!!error}
          helperText={error}
          fullWidth
          margin="normal"
          size="small"
          InputLabelProps={type === "date" ? { shrink: true } : {}}
          InputProps={
            type === "password"
              ? {
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={togglePasswordVisibility}>
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }
              : null
          }
          {...props}
        />
      )}
    </div>
  );
};

export default TextFieldWithValidation;
