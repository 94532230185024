import React from "react";
import TextFieldWithValidation from "./TextFieldWithValidation";
import { Box } from "@mui/material";

const DirectorForm = ({ formData, handleInputChange, handleBlur, errors }) => (
  <>
    <Box className="name-fields">
      <TextFieldWithValidation
        name="firstName"
        label="First Name"
        value={formData.firstName}
        onChange={handleInputChange}
        onBlur={handleBlur}
        error={errors.firstName}
      />
      <TextFieldWithValidation
        name="lastName"
        label="Last Name"
        value={formData.lastName}
        onChange={handleInputChange}
        onBlur={handleBlur}
        error={errors.lastName}
      />
    </Box>
    <Box className="name-fields">
      <TextFieldWithValidation
        name="zipCode"
        label="Zip Code"
        value={formData.zipCode}
        onChange={handleInputChange}
        onBlur={handleBlur}
        error={errors.zipCode}
      />
      <TextFieldWithValidation
        name="phoneNumber"
        label="Phone Number"
        value={formData.phoneNumber}
        onChange={handleInputChange}
        onBlur={handleBlur}
        error={errors.phoneNumber}
      />
    </Box>
    <TextFieldWithValidation
      name="selectedRole"
      label="Selecte Role"
      value={formData.selectedRole}
      onChange={handleInputChange}
      onBlur={handleBlur}
      error={errors.selectedRole}
      select
      options={[
        { value: "director", label: "Director" },
        { value: "producer", label: "Producer" },
      ]}
    />
    <TextFieldWithValidation
      name="email"
      label="Email"
      value={formData.email}
      onChange={handleInputChange}
      onBlur={handleBlur}
      error={errors.email}
    />
    <TextFieldWithValidation
      name="password"
      label="Create Password"
      type="password"
      value={formData.password}
      onChange={handleInputChange}
      onBlur={handleBlur}
      error={errors.password}
    />
    <TextFieldWithValidation
      name="confirmPassword"
      label="Confirm Password"
      type="password"
      value={formData.confirmPassword}
      onChange={handleInputChange}
      onBlur={handleBlur}
      error={errors.confirmPassword}
    />
  </>
);

export default DirectorForm;
