import React, { useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import CategoryDropdown from '../../../components/categorydropdown/CategoryDropdown';

const InfoSection = ({ isDropdownVisible, toggleDropdown, selectedCategories, handleCategoryChange }) => (
  <div className="profile-info">
    <div className="info-container">
      <div className="info-title">
        ABOUT:
        <FontAwesomeIcon icon={faEdit} className="edit-icon" />
      </div>
      <div className="info-box">
        <p>Introduce yourself to the community - write a short, catchy description.</p>
        <a href="#" className="add-link">Add</a>
      </div>
    </div>
    <div className="info-row">
      <div className="info-container">
        <div className="info-title">
          CATEGORIES:
          <FontAwesomeIcon icon={faEdit} className="edit-icon" />
        </div>
        <div className="info-box">
          {!isDropdownVisible ? (
            <>
              <p>Please, choose the most popular categories of your casting calls.</p>
              <a
                href="#"
                className="add-link"
                style={{ float: "left", marginLeft: "-650px", marginTop: "45px" }}
                onClick={toggleDropdown}
              >
                Add
              </a>
              <div className="selected-categories">
                {selectedCategories.map((category) => (
                  <span key={category} className="category-circle">{category}</span>
                ))}
              </div>
            </>
          ) : (
            <CategoryDropdown
              selectedCategories={selectedCategories}
              handleCategoryChange={handleCategoryChange}
              toggleDropdown={toggleDropdown}
            />
          )}
        </div>
      </div>
    </div>
    <div className="info-container">
      <div className="info-title">
        PERSONALS:
        <FontAwesomeIcon icon={faEdit} className="edit-icon" />
      </div>
      <div className="info-box">
        <p>To build credibility, add your company information and select a location for most of your casting calls in your profile.</p>
        <a href="#" className="add-link">Add</a>
      </div>
    </div>
  </div>
);

export default InfoSection;
