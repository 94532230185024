import React, {useEffect} from "react";
import ActorDetails from "../../components/actorProfile/ActorDetails";
import actor1 from "../../assets/actor1.jpg";
import fblogo from "../../assets/fb.png";
import instalogo from "../../assets/insta.png";
import YoutubeList from "../../components/actorProfile/YoutubeList";
import PhotoPreview from "../../components/actorProfile/PhotoPreview";
import Layout from "../../components/layout/Layout";
import Sidebar from "../../components/actorProfile/Sidebar";
import "../../components/actorProfile/Sidebar.css";

function ActorProfile() {

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  
  const actor = {
    profilePict: actor1,
    name: "John Doe",
    rating: 8841,
    profileId: "12345",
    interests: ["Acting", "Directing"],
    age: 10,
    ethnicity: "Caucasian",
    appearance: "Tall with blue eyes",
    socialProfiles1: fblogo,
    socialProfiles2: instalogo,
    talents: ["Singing", "Dancing"],
    about:
      "John is an experienced actor with a deep passion for the performing arts. Over the years, he has honed his craft, taking on a variety of challenging roles across different genres. His dedication to his art is evident in his performances, which are marked by a nuanced understanding of character and a commitment to authenticity. John has not only performed in numerous stage productions but has also worked in film and television, earning accolades for his versatility and skill. His journey in the performing arts is driven by a love for storytelling and a desire to connect with audiences on a profound level. Whether playing a lead role or supporting character, John brings a unique energy and intensity to his work, making him a respected and admired figure in the industry.",
    hobbies: ["Reading", "Traveling"],
  };

  return (
    <Layout>
      <div className="actor-profile-main" style={{ display: "flex" }}>
        <div style={{ flex: 1 }}>
          <ActorDetails
            id="overview"
            profilePict={actor.profilePict}
            name={actor.name}
            rating={actor.rating}
            profileId={actor.profileId}
            interests={actor.interests}
            age={actor.age}
            ethnicity={actor.ethnicity}
            appearance={actor.appearance}
            socialProfiles1={actor.socialProfiles1}
            socialProfiles2={actor.socialProfiles2}
            talents={actor.talents}
            about={actor.about}
            hobbies={actor.hobbies}
          />
          <div id="youtubeList">
            <YoutubeList />
          </div>
          <div id="photoPreview">
            <PhotoPreview />
          </div>
        </div>
        <Sidebar />
      </div>
    </Layout>
  );
}

export default ActorProfile;
