import { Outlet, Navigate } from "react-router-dom";
import React from "react";

const HomePrivateRoutes = () => {

  const auth = localStorage.getItem("isAuthenticated");

  return auth ? <Navigate to="/castingcalls" /> : <Outlet />;
};

export default HomePrivateRoutes;
