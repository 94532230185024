import React from 'react';

const ProfileTabs = ({ activeTab, handleTabClick }) => (
  <div className="profile-tabs">
    <button
      className={activeTab === "info" ? "active" : ""}
      onClick={() => handleTabClick("info")}
    >
      Info
    </button>
    <button
      className={activeTab === "assets" ? "active" : ""}
      onClick={() => handleTabClick("assets")}
    >
      Photos
    </button>
  </div>
);

export default ProfileTabs;
