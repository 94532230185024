import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faTiktok,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import ContactSupportPopup from "../Contactus/ContactSupportPopup";
import PrivacyPolicyPopup from "../agreement/privacyPolicy/PrivacyPolicy";
import TermOfUsePopup from "../agreement/termOfUse/TermOfUse";

const Footer = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [privacyPolicyOpen, setPrivacyPolicyOpen] = useState(false);
  const [termOfUseOpen, setTermOfUseOpen] = useState(false);

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  const openPrivacyPolicy = (e) => {
    e.preventDefault();
    setPrivacyPolicyOpen(true);
  };

  const openTermOfUse = (e) => {
    e.preventDefault();
    setTermOfUseOpen(true);
  };

  return (
    <div className="footer-full-wrapper">
      <div className="footer-container">
        <div className="footer-top">
          <div className="follow">Follow us</div>
          <div className="social-line">
            <div className="social-icons">
              <a
                href="https://www.facebook.com/opencurtainscasting/"
                target="_blank"
                rel="noopener noreferrer"
                className="social-icon"
              >
                <FontAwesomeIcon icon={faFacebookF} />
              </a>
              <a
                href="https://www.instagram.com/open.curtains.casting/"
                target="_blank"
                rel="noopener noreferrer"
                className="social-icon"
              >
                <FontAwesomeIcon icon={faInstagram} />
              </a>
              <a
                href="https://www.tiktok.com/@open.curtains.casting?lang=en"
                target="_blank"
                rel="noopener noreferrer"
                className="social-icon"
              >
                <FontAwesomeIcon icon={faTiktok} />
              </a>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="footer-link-container">
            <span className="footer-link" onClick={openTermOfUse}>
              Terms & Conditions
            </span>

            <TermOfUsePopup
              open={termOfUseOpen}
              onClose={() => setTermOfUseOpen(false)}
            />

            <span className="footer-link" onClick={openPrivacyPolicy}>
              Privacy & Policy
            </span>

            <PrivacyPolicyPopup
              open={privacyPolicyOpen}
              onClose={() => setPrivacyPolicyOpen(false)}
            />

            <a href="/aboutUs" className="footer-link">
              About
            </a>
            {/* Update the Contact Us link to trigger the popup */}
            <span
              className="footer-link"
              onClick={(e) => {
                e.preventDefault();
                togglePopup();
              }}
            >
              Contact Us
            </span>
          </div>
          <span className="copy">
            &copy; {new Date().getFullYear()} Open Curtains Casting.
          </span>
        </div>
      </div>
      {/* Render the popup when isPopupOpen is true */}
      {isPopupOpen && <ContactSupportPopup onClose={togglePopup} />}
    </div>
  );
};

export default Footer;
