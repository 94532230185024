import { Outlet, Navigate } from "react-router-dom";
import React from "react";

const ProducerPrivateRoutes = () => {
  const role = localStorage.getItem("role");
  const isAuthenticated = localStorage.getItem("isAuthenticated");

  const auth = !(isAuthenticated && role === "director");

  return auth ? <Outlet /> : <Navigate to="/profile" />;
};

export default ProducerPrivateRoutes;
