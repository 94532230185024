import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons"; 
import ProfilePhoto from "./ProfilePhoto";
import ContactInfo from "./ContactInfo";
import InfoSection from "./InfoSection";
import ProfileTabs from "./ProfileTabs";
import "./director.css"
import VideoPage from "../../photopage/VideoPage";

const DirectorProfilePage1 = () => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [activeTab, setActiveTab] = useState("info");
  const [headshot, setHeadshot] = useState(
    "https://pro.kidscasting.com/assets/placeholders/circle-casting-director.svg"
  );

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setHeadshot(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const toggleDropdown = () => {
    setIsDropdownVisible(!isDropdownVisible);
  };

  const handleCategoryChange = (categories) => {
    setSelectedCategories(categories);
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="profile-container">
      <div className="profile-header">
        <h1>
          Casting Director
          <FontAwesomeIcon icon={faEdit} className="edit-icon" />
        </h1>
      </div>
      <div className="profile-content">
        <div className="profile-sidebar">
          <ProfilePhoto headshot={headshot} handleImageChange={handleImageChange} />
          <ContactInfo />
        </div>
        <div className="profile-main">
          <ProfileTabs activeTab={activeTab} handleTabClick={handleTabClick} />
          <div className="profile-section">
            {activeTab === "info" && (
              <InfoSection
                isDropdownVisible={isDropdownVisible}
                toggleDropdown={toggleDropdown}
                selectedCategories={selectedCategories}
                handleCategoryChange={handleCategoryChange}
              />
            )}
            {activeTab === "assets" && <VideoPage />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DirectorProfilePage1;
