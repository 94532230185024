import React, { useState } from 'react';
import './CategoryDropdown.css';

const CategoryDropdown = ({ selectedCategories, handleCategoryChange, toggleDropdown }) => {
  const [tempSelectedCategories, setTempSelectedCategories] = useState(selectedCategories);

  const handleCheckboxChange = (event) => {
    const value = event.target.value;
    setTempSelectedCategories(prevState => 
      prevState.includes(value)
        ? prevState.filter(category => category !== value)
        : [...prevState, value]
    );
  };

  const handleOkClick = () => {
    handleCategoryChange(tempSelectedCategories);
    toggleDropdown();
  };

  return (
    <div className="dropdown-container">
      <div className="dropdown-header">
        <span>Choose Categories</span>
      </div>
      <div className="dropdown-list">
        {['Acting', 'Modeling', 'Singing', 'Dancing'].map(category => (
          <div key={category} className="dropdown-item">
            <input
              type="checkbox"
              id={category}
              value={category}
              checked={tempSelectedCategories.includes(category)}
              onChange={handleCheckboxChange}
            />
            <label htmlFor={category}>{category}</label>
          </div>
        ))}
      </div>
      <div className="dropdown-footer">
        <button onClick={handleOkClick}>OK</button>
      </div>
    </div>
  );
};

export default CategoryDropdown;
