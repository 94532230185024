import React, { useEffect } from "react";
import "./home.css";
import HomeImage from "../../assets/homePage.png";
import { useNavigate } from "react-router-dom";

const Top = () => {
  const navigate = useNavigate();

  const handleRegisterClick = () => {
    navigate("/register");
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <div className="home-top-full-wrapper">
      <div className="home-top-conainer">
        <div className="homeTop-small-image">
          <img className="home-small-image" src={HomeImage} alt="" />
        </div>
        <div className="homeTop-left">
          <span className="sub-title">
            Unleash the Magic of Youth – Find Your Star with Us!
          </span>
          <span className="main-title">
            Start your journey to stardom today with Open Curtains Casting!
          </span>
          <span className="subColor-title">
            Explore thousands of updated casting calls, from commercials to
            feature films
          </span>
          <div className="homeTop-buttons">
            <button className="homeTop-join" onClick={handleRegisterClick}>
              Join Now
            </button>
            <button className="homeMiddle2" onClick={handleRegisterClick}>
              Post Your Casting Call
            </button>
          </div>
        </div>
        <div className="homeTop-right">
          <img className="home-image" src={HomeImage} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Top;
