import { Outlet, Navigate } from "react-router-dom";
import React from "react";

const DirectorPrivateRoutes = () => {
  const role = localStorage.getItem("role");
  const auth = role === "director" || role === "producer";

  return auth ? <Outlet /> : <Navigate to="/profile" />;
};

export default DirectorPrivateRoutes;
