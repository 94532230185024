import React, { useState, useEffect, useContext } from "react";
import "./review.css";
import Avatar from "../../assets/avatar.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Rating from "@mui/material/Rating";
import Modal from "@mui/material/Modal";
import { TextField, Typography } from "@mui/material";
import {
  addReviews,
  getDirectorDetails,
  getProducerDetails,
  getUserDetails,
  getCrewDetails,
} from "../../api/axios";
import Alert from "../common/Alert";
import { AuthContext } from "../../context/AuthContext";

const ReviewAddModal = ({
  openReviewAddModal,
  setOpenReviewAddModal,
  GetAllReviews,
}) => {
  const { isAuthenticated, role, userId } = useContext(AuthContext);

  const initialValue = {
    userName: "",
    description: "",
    rateValue: 0,
  };
  const [formData, setFormData] = useState(initialValue);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");

  const [userDetails, setUserDetails] = useState({});

  //fetch user details for set default user name
  const GetUserDetails = async (id, role) => {
    try {
      let data;
      switch (role) {
        case "actor":
          data = await getUserDetails(id);
          break;
        case "director":
          data = await getDirectorDetails(id);
          break;
        case "producer":
          data = await getProducerDetails(id);
          break;
        case "crew":
          data = await getCrewDetails(id);
          break;
        default:
          console.error("Invalid role");
          return;
      }

      if (data && data.success) {
        setUserDetails(data.user);
        setFormData((prevDetails) => ({
          ...prevDetails,
          userName: `${data.user.registerResult.firstName} ${data.user.registerResult.lastName}`,
        }));
      }
    } catch (error) {
      console.error("Error fetching user data", error);
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      GetUserDetails(userId, role);
    }
  }, [isAuthenticated, openReviewAddModal, userId, role]);

  const userOtherDetails = userDetails?.result || {};

  //close the modal
  const handleClose = () => {
    setOpenReviewAddModal(!openReviewAddModal);
    setFormData(initialValue);
    setErrors({});
  };

  //save input changes
  const handleInputChange = (name, value) => {
    setFormData((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  //validate form
  const validateForm = () => {
    const errors = {};

    if (!formData.userName) {
      errors.userName = "Please add your name";
    }
    if (!formData.description) {
      errors.description = "Please provide your review";
    }
    if (!formData.rateValue) {
      errors.rateValue = "Please rate your experience";
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  //submit form
  const handleSubmit = async () => {
    if (validateForm()) {
      try {
        setLoading(true);

        const body = {
          rateValue: formData.rateValue,
          description: formData.description,
        };

        const res = await addReviews(userId, body);
        if (res.status) {
          handleClose();
          GetAllReviews();
          setAlertSeverity("success");
          setAlertMessage("Review add successfull!");
        }
      } catch (error) {
        console.error("Review add failed:", error);
        setAlertSeverity("error");
        setAlertMessage("Review add faild!");
      } finally {
        setOpenSnackbar(true);
        setLoading(false);
      }
    }
  };

  return (
    <>
      <Modal open={openReviewAddModal} onClose={handleClose}>
        <div className="review-add-modal-wrapper">
          <div className="review-add-modal-container">
            <div className="modal-close-icon" onClick={handleClose}>
              <FontAwesomeIcon icon={faTimes} />{" "}
            </div>
            <div className="review-add-avatar">
              <img
                src={userOtherDetails.imageURL || Avatar}
                alt=""
                className="review-auth-avatar"
              />
            </div>
            <span className="director-top-title">Tell your story</span>
            <div className="rate-input">
              <span>Rate this site</span>
              <Rating
                name="simple-controlled"
                value={formData.rateValue}
                onChange={(event, newValue) => {
                  handleInputChange("rateValue", newValue);
                }}
              />
            </div>
            {errors.rateValue && (
              <Typography variant="caption" color="error">
                {errors.rateValue}
              </Typography>
            )}
            <TextField
              label="Profile"
              name="userName"
              value={formData.userName}
              variant="filled"
              fullWidth
              sx={{
                backgroundColor: "transparent",
                "& .MuiFilledInput-root": {
                  backgroundColor: "transparent",
                },
                mt: "10px",
              }}
              error={!!errors.userName}
              helperText={errors.userName}
              InputProps={{
                readOnly: true,
              }}
            />
            <TextField
              label="Type your review here"
              name="description"
              value={formData.description}
              onChange={(e) => handleInputChange("description", e.target.value)}
              variant="filled"
              fullWidth
              sx={{
                backgroundColor: "transparent",
                "& .MuiFilledInput-root": {
                  backgroundColor: "transparent",
                },
                mt: "10px",
              }}
              error={!!errors.description}
              helperText={errors.description}
            />
            <div>
              <button className="review-submit-button" onClick={handleSubmit}>
                {loading ? "Submitting.." : "Submit"}
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <Alert
        openSnackbar={openSnackbar}
        alertSeverity={alertSeverity}
        setOpenSnackbar={setOpenSnackbar}
        alertMessage={alertMessage}
      />
    </>
  );
};

export default ReviewAddModal;
