import React from "react";
import "./actorDetails.css";

const ActorDetails = ({
  profilePict,
  name,
  rating,
  profileId,
  interests = [],
  age,
  ethnicity,
  appearance,
  socialProfiles1,
  socialProfiles2,
  talents = [],
  about,
  hobbies = [],
}) => {
  return (
    <div className="actor-profile-container">
      <div className="Profile-pic-area">
        <div className="upper-image">
          <img
            src={profilePict}
            alt={`${name}'s profile`}
            className="actor-profile-pic"
          />
        </div>
        <div className="lower-image">
          <img
            src={profilePict}
            alt={`${name}'s profile`}
            className="actor-profile-pic-1"
          />
          <img
            src={profilePict}
            alt={`${name}'s profile`}
            className="actor-profile-pic-2"
          />
        </div>
      </div>

      <div className="actor-profile-details">
        <h1 className="actor-profile-name">{name}</h1>
        <div className="actor-profile-info">
          <div className="actor-profile-rating">
            <span className="star">&#9733;</span>
            <span className="ratingNumber">{rating}</span>
          </div>
          <div className="actor-profile-id">
            <p>Profile ID: {profileId}</p>
          </div>
        </div>

        <hr className="modal-divider" />

        <section id="interests">
          <p>
            <strong>Interested in:</strong> <br />
            {interests.join(", ")}
          </p>
        </section>

        <section id="age">
          <p>
            <strong>Age:</strong> <br />
            {age}
          </p>
        </section>

        <section id="ethnicity">
          <p>
            <strong>Ethnicity:</strong> <br />
            {ethnicity}
          </p>
        </section>

        <section id="appearance">
          <p>
            <strong>Appearance:</strong> <br />
            {appearance}
          </p>
        </section>

        <section id="socialprofiles">
          <p>
            <strong>Social Profiles:</strong> <br />
            <div className="social-media-logo">
              <img
                src={socialProfiles1}
                alt={`${name}'s profile`}
                className="social-media-logo-1"
              />
              <img
                src={socialProfiles2}
                alt={`${name}'s profile`}
                className="social-media-logo-2"
              />
            </div>
          </p>
        </section>

        <section id="talents">
          <p>
            <strong>Talents:</strong> <br />
            {talents.map((talent, index) => (
              <span key={index} className="talent-item">
                {talent}
              </span>
            ))}
          </p>
        </section>

        <section id="about">
          <p>
            <strong>About:</strong> <br />
            {about}
          </p>
        </section>

        <section id="hobbies">
          <p>
            <strong>Hobbies:</strong> <br />
            {hobbies.join(", ")}
          </p>
        </section>
      </div>
    </div>
  );
};

export default ActorDetails;
