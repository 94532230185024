import React from 'react';

const PlanOption = ({ plan, isSelected, onSelect }) => {
  const handleCardClick = () => {
    if (!isSelected) {
      onSelect();
    }
  };

  return (
    <div 
      className={`plan ${isSelected ? 'selected' : ''} ${plan.isBestDeal ? 'best-deal' : ''}`} 
      onClick={handleCardClick}
    >
      <div className="part duration-part">
        <input
          type="radio"
          id={`plan${plan.duration}`}
          name="plan"
          value={plan.duration}
          checked={isSelected}
          onChange={onSelect}
        />
        <label htmlFor={`plan${plan.duration}`} className="duration">
          {plan.duration} 
        </label>
      </div>
      <div className="part price-part">
        {/* <span className="original-price">${plan.originalPrice}</span> */}
        <span className="price">${plan.price}</span>
        {/* <span className="per-month">per month</span> */}
      </div>
      {/* <div className="part savings-part">
        <span className="savings"><span className='save'>save</span> {plan.savingsPercentage}%<sup>1</sup></span>
        {plan.hasGuarantee && <span className="guarantee">+KidsCasting guarantee</span>}
      </div> */}
    </div>
  );
};

export default PlanOption;
