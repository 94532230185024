import React from 'react'
import Layout from "../../components/layout/Layout";
import ActorsList from "../../components/actors/ActorsList";
import CastingCallSlide from '../../components/castingCalls/castingCallSlide';

function ActorCrew() {
  return (
    <Layout>
      <div>
        <CastingCallSlide />
        <ActorsList/>
      </div>
    </Layout>
  )
}

export default ActorCrew
