import React from "react";
import Layout from "../../components/layout/Layout";
import Top from "../../components/castingDirector/Top";
import Middle from "../../components/castingDirector/Middle";
import HowWork from "../../components/castingDirector/HowWork";


const CastingDirector = () => {
  return (
    <Layout>
      <div>
        <Top />
        {/* <Middle/> */}
        <HowWork />
      </div>
    </Layout>
  );
};

export default CastingDirector;
