import React, { useState } from "react";
import "./actorDetails.css";
import image1 from "../../assets/actor1.jpg";
import image2 from "../../assets/actor2.jpg";
import image3 from "../../assets/actor3.jpg";
import image4 from "../../assets/actor4.jpg";
import image5 from "../../assets/actor5.jpg";
import image6 from "../../assets/actor6.jpg";
import image7 from "../../assets/actor7.jpg";
import image8 from "../../assets/actor8.jpg";

const photos = [image1, image2, image3, image4, image5, image6, image7, image8];

const PhotoPreview = () => {
  const [visiblePhotos, setVisiblePhotos] = useState(4);

  const loadMorePhotos = () => {
    setVisiblePhotos((prevVisiblePhotos) => {
      return Math.min(prevVisiblePhotos + 4, photos.length);
    });
  };

  return (
    <div className="more-photos-grid">
      <h2>Photos</h2>
      <div className="photo-grid">
        {photos.slice(0, visiblePhotos).map((photo, index) => (
          <div key={index} className="photo-container">
            <img src={photo} alt={`photo-${index}`} className="photo" />
          </div>
        ))}
      </div>
      {visiblePhotos < photos.length && (
        <button onClick={loadMorePhotos} className="load-more-button">
          Load more
        </button>
      )}
    </div>
  );
};

export default PhotoPreview;
