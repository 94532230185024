import {
  faUser,
  faBullhorn,
  faPaperPlane,
  faClipboardList,
} from "@fortawesome/free-solid-svg-icons";


export const workFlow = [
  {
    title: "1. Sign Up",
    des: "Signing up as a Casting Director will only take you a minute.",
    icon: faUser,
  },
  {
    title: "2. Post a Casting Call",
    des: "Post a detailed project description and access a broad database of potential candidates.",
    icon: faBullhorn,
  },
  {
    title: "3. Shortlist Submissions",
    des: "In our virtual space, you can view all the applicants and select matching performers with one click.",
    icon: faClipboardList,
  },
  {
    title: "4. Contact Actor",
    des: "Contact selected performers and share information/instructions via our messaging center.",
    icon: faPaperPlane,
  },
];

export const siteStat = [
  {
    value: "12500",
    topic: "trusted casting professionals",
  },
  {
    value: "15,000",
    topic: "casting projects posted yearly",
  },
  {
    value: "12",
    topic: "years in the industry",
  },
  {
    value: "4M",
    topic: "active actors",
  },
];

export const whoWork = [
  {
    title: "Casting Directors & Film Makers",
    description:
      "No matter your casting needs, KidsCasting is here to help, offering a wide range of talent options from extras to speaking roles to stunt doubles and beyond, from small projects to major networks like HBO.",
  },
  {
    title: "Brands",
    description:
      "Leading brands such as Chicco, Carter's and Disney have successfully discovered talent through KidsCasting. Explore our database of over 4 million talented kids, to fulfill your dream project.",
  },
  {
    title: "Modeling & Talent Agencies",
    description:
      "Whether you are looking for new faces for the runway, commercial or other projects, KidsCasting has you covered. With NYFW and MMG already booking talent through our platform, you can trust that our database will bring your project to life.",
  },
  {
    title: "Marketing Agencies",
    description:
      "Are you looking for talented individuals to feature in your advertising campaign? Our platform has gifted young actors, models and performers, waiting to be cast for your next event.",
  },
  {
    title: "Independent Creators",
    description:
      "Looking to add talent to your photography portfolio or film project? Look no further; our platform offers countless options to help you achieve your vision.",
  },
];

