import React from "react";
import NavBar from "./NavBar";
import Sidebar from "../sidebar/Sidebar";

const ProfileLayout = ({ children }) => (
  <div>
    <NavBar />
    <div style={{ display: "flex" }}>
      <Sidebar />
      <main>{children}</main>
    </div>
  </div>
);

export default ProfileLayout;
