import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import "./Layout.css";
import { NavLink, useLocation } from "react-router-dom";
import LoginPopup from "../auth/LoginPopup";
import logo from "../../assets/logo.jpeg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import { Drawer, List, ListItemButton, ListItemText } from "@mui/material";
import { AuthContext } from "../../context/AuthContext";

const NavBar = () => {
  const { isAuthenticated, role } = useContext(AuthContext);
  const [isLoginOpen, setIsLoginOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  //drawer open for small devices
  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  //check active path for highlight
  const isActive = (path) => location.pathname.startsWith(path);

  //button click functions
  const handleClick = () => {
    navigate("/register");
  };

  const handleClickSubscribe = () => {
    navigate("/subscriptions");
  };

  const handleClickCastingCallCreate = () => {
    navigate("/castingcalls/create");
  };

  return (
    <div className="navbar-full-wrapper">
      <div className="navbar-container">
        <NavLink to="/">
          <img src={logo} alt="" className="logo" />
        </NavLink>
        <div className="navLink-container">
          <NavLink
            to="/castingcalls"
            className={`navLink ${isActive("/castingcalls") ? "active" : ""}`}
          >
            Casting Calls
          </NavLink>
          <NavLink
            to="/actors"
            className={`navLink ${isActive("/actors") ? "active" : ""}`}
          >
            Actors
          </NavLink>
          {(!isAuthenticated || role !== "director") && (
            <NavLink
              to="/crew"
              className={`navLink ${isActive("/actorcrew") ? "active" : ""}`}
            >
              Crew
            </NavLink>
          )}
          {!isAuthenticated && (
            <NavLink
              to="/castingdirectors"
              className={`navLink ${
                isActive("/castingdirectors") ? "active" : ""
              }`}
            >
              Casting Directors
            </NavLink>
          )}
          <NavLink
            to="/reviews"
            className={`navLink ${isActive("/reviews") ? "active" : ""}`}
          >
            Reviews
          </NavLink>
          {!isAuthenticated && (
            <NavLink
              to="/login"
              className="navLink"
              style={{ color: "#e54447" }}
              onClick={(e) => {
                e.preventDefault();
                setIsLoginOpen(true);
              }}
            >
              Login
            </NavLink>
          )}
          {!isAuthenticated && (
            <button className="navbar-button" onClick={handleClick}>
              Join Now
            </button>
          )}
          {isAuthenticated && (role === "actor" || role === "crew") && (
            <div className="subscribe-button-wrapper">
              <button
                className="subscribe-button"
                onClick={handleClickSubscribe}
              >
                SUBSCRIBE NOW
              </button>
            </div>
          )}
          {isAuthenticated && (role === "director" || role === "producer") && (
            <div className="subscribe-button-wrapper">
              <button
                className="subscribe-button"
                onClick={handleClickCastingCallCreate}
              >
                POST A CASTING CALL
              </button>
            </div>
          )}
        </div>
        <FontAwesomeIcon
          icon={faBars}
          className="nav-menu-icon"
          onClick={toggleDrawer(true)}
        />
      </div>
      <LoginPopup isLoginOpen={isLoginOpen} setIsLoginOpen={setIsLoginOpen} />
      <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
        <List className="drawer-wrapper">
          <div className="drawer-close-icon" onClick={toggleDrawer(false)}>
            <FontAwesomeIcon icon={faTimes} />{" "}
          </div>
          <div className="drawer-top-container">
            <NavLink to="/">
              <img src={logo} alt="" className="drawer-logo" />
            </NavLink>
            {!isAuthenticated && (
              <button
                className="navbar-button"
                onClick={() => {
                  handleClick();
                  toggleDrawer(false);
                }}
              >
                SIGN UP
              </button>
            )}
            {!isAuthenticated && (
              <NavLink
                to="/login"
                className="navLink"
                style={{ color: "#3491e8" }}
                onClick={(e) => {
                  e.preventDefault();
                  setIsLoginOpen(true);
                  toggleDrawer(false);
                }}
              >
                Login
              </NavLink>
            )}
            {isAuthenticated && (role === "actor" || role === "crew") && (
              <div className="subscribe-button-wrapper">
                <button
                  className="subscribe-button"
                  onClick={() => {
                    handleClickSubscribe();
                    toggleDrawer(false);
                  }}
                >
                  SUBSCRIBE NOW
                </button>
              </div>
            )}
            {isAuthenticated &&
              (role === "director" || role === "producer") && (
                <div className="subscribe-button-wrapper">
                  <button
                    className="subscribe-button"
                    onClick={() => {
                      handleClickCastingCallCreate();
                      toggleDrawer(false);
                    }}
                  >
                    POST A CASTING CALL
                  </button>
                </div>
              )}
            <div className="seperate-line"></div>
          </div>
          <ListItemButton
            onClick={toggleDrawer(false)}
            sx={{ paddingLeft: "40px" }}
          >
            <NavLink
              to="/castingcalls"
              className={`navLink ${isActive("/castingcalls") ? "active" : ""}`}
            >
              <ListItemText primary="Casting Calls" />
            </NavLink>
          </ListItemButton>
          <ListItemButton
            onClick={toggleDrawer(false)}
            sx={{ paddingLeft: "40px" }}
          >
            <NavLink
              to="/actors"
              className={`navLink ${isActive("/actors") ? "active" : ""}`}
            >
              <ListItemText primary="Actors" />
            </NavLink>
          </ListItemButton>
          {!isAuthenticated || role !== "director" ? (
            <ListItemButton
              onClick={toggleDrawer(false)}
              sx={{ paddingLeft: "40px" }}
            >
              <NavLink
                to="/crew"
                className={`navLink ${isActive("/crew") ? "active" : ""}`}
              >
                <ListItemText primary="Crew" />
              </NavLink>
            </ListItemButton>
          ) : null}
          {!isAuthenticated && (
            <ListItemButton
              onClick={toggleDrawer(false)}
              sx={{ paddingLeft: "40px" }}
            >
              <NavLink
                to="/castingdirectors"
                className={`navLink ${
                  isActive("/castingdirectors") ? "active" : ""
                }`}
              >
                <ListItemText primary="Casting Directors" />
              </NavLink>
            </ListItemButton>
          )}
          <ListItemButton
            onClick={toggleDrawer(false)}
            sx={{ paddingLeft: "40px" }}
          >
            <NavLink
              to="/reviews"
              className={`navLink ${isActive("/reviews") ? "active" : ""}`}
            >
              <ListItemText primary="Reviews" />
            </NavLink>
          </ListItemButton>
        </List>
      </Drawer>
    </div>
  );
};

export default NavBar;
