import React, { useState } from "react";
import "./name.css"; 

const EditNamePopup = ({ isOpen, onClose, currentFirstName, currentLastName, onSave }) => {
  const [firstName, setFirstName] = useState(currentFirstName);
  const [lastName, setLastName] = useState(currentLastName);

  const handleSave = () => {
    onSave(firstName, lastName);
    onClose();
  };

  if (!isOpen) return null; 

  return (
    <div className="edit-name-popup">
      <div className="edit-name-popup-content">
        <div className="edit-name-popup-header">
          <h2>Edit Name</h2>
        </div>
        <div className="edit-name-popup-body">
          <div className="edit-name-popup-field">
            <label htmlFor="firstName">First Name</label>
            <input
              type="text"
              id="firstName"
              className="edit-name-input"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </div>
          <div className="edit-name-popup-field">
            <label htmlFor="lastName">Last Name</label>
            <input
              type="text"
              id="lastName"
              className="edit-name-input"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </div>
        </div>
        <div className="edit-name-popup-footer">
          <button className="edit-name-save-button" onClick={handleSave}>
            Save
          </button>
          <button className="cancel-button" onClick={onClose}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditNamePopup;
