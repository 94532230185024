import React from "react";
import './contactSupportPopup.css';

const ContactSupportPopup = ({ onClose }) => {
  return (
    <div className="popup-overlay1">
      <div className="popup-content">
        <button className="close-button" onClick={onClose}>
          &times;
        </button>
        <h2>Contact Support</h2>
        
        
        <div className="contact-section">
          <div className="section-separator">
            
          </div>
          <p>
            <a href="tel:18008465578">1 (800) 846-5578</a><br />
            Monday-Friday 8am-5pm PST<br />
            <a href="mailto:help@opencurtainscasting.com">help@opencurtainscasting.com</a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ContactSupportPopup;
