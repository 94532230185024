import React, { useState, useEffect } from 'react';
import './editpopup.css';


const EditPopup = ({ title, fields = {}, onSave, onClose }) => {
  const [values, setValues] = useState({
    facebook: '',
    instagram: '',
    xlink: '',
    tiktok: '',
    youtube: '',
    email: '',
    contactNumber: '',
    ...fields,
  });

  useEffect(() => {
    setValues({
      facebook: fields.facebook || '',
      instagram: fields.instagram || '',
      xlink: fields.xlink || '',
      tiktok: fields.tiktok || '',
      youtube: fields.youtube || '',
      email: fields.email || '',
      contactNumber: fields.contactNumber || '',
    });
  }, [fields]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleSave = () => {
    onSave(values);
    onClose();
  };

  return (
    <div className="edit-popup">
      <div className="edit-popup-content">
        <div className="edit-popup-header">
          <h2>{title}</h2>
        </div>
        <div className="edit-popup-body">
          {title === 'Edit Email' ? (
            <div className="edit-popup-field">
              <label>Email</label>
              <input
                type="email"
                name="email"
                value={values.email}
                onChange={handleChange}
              />
            </div>
          ) : title === 'Edit Contact Number' ? (
            <div className="edit-popup-field">
              <label>Contact Number</label>
              <input
                type="text"
                name="contactNumber"
                value={values.contactNumber}
                onChange={handleChange}
              />
            </div>
          ) : (
            <>
              <div className="edit-popup-field">
                <label>Facebook</label>
                <input
                  type="text"
                  name="facebook"
                  value={values.facebook}
                  onChange={handleChange}
                />
              </div>
              <div className="edit-popup-field">
                <label>Instagram</label>
                <input
                  type="text"
                  name="instagram"
                  value={values.instagram}
                  onChange={handleChange}
                />
              </div>
              <div className="edit-popup-field">
                <label>Xlink</label>
                <input
                  type="text"
                  name="xlink"
                  value={values.xlink}
                  onChange={handleChange}
                />
              </div>
              <div className="edit-popup-field">
                <label>TikTok</label>
                <input
                  type="text"
                  name="tiktok"
                  value={values.tiktok}
                  onChange={handleChange}
                />
              </div>
              <div className="edit-popup-field">
                <label>YouTube</label>
                <input
                  type="text"
                  name="youtube"
                  value={values.youtube}
                  onChange={handleChange}
                />
              </div>
            </>
          )}
        </div>
        <div className="edit-popup-footer">
          <button onClick={handleSave}>Save</button>
          <button className="cancel-button" onClick={onClose}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditPopup;
