import React, { useContext } from "react";
import NavBar from "./NavBar";
import Footer from "./Footer";
import Sidebar from "../sidebar/Sidebar";
import { AuthContext } from "../../context/AuthContext";

const Layout = ({ children }) => {
  const { isAuthenticated } = useContext(AuthContext);

  return (
    <div>
      <NavBar />
      <div>
        {isAuthenticated && <Sidebar />}
        <main>{children}</main>
      </div>
      {!isAuthenticated && <Footer />}
    </div>
  );
};

export default Layout;
