import React from "react";
import Layout from "../../components/layout/Layout";
import CastingCallView from "../../components/castingCalls/CastingCallView";

const CastingCallViewPage = () => {
  return (
    <Layout>
      <div>
        <CastingCallView />
      </div>
    </Layout>
  );
};

export default CastingCallViewPage;
