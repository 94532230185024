import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import EditPopup from '../../EditPopup/EditPopup';
const ContactInfo = () => {
  const [popupFields, setPopupFields] = useState({});
  const [popupTitle, setPopupTitle] = useState("");
  const [isPopupVisible, setIsPopupVisible] = useState(false);
 
  const [profileData, setProfileData] = useState({
    email: "example@example.com",
    personalLink: "Add your custom URL or social media links.",
    socialNetwork: "Add your social network links.",
  });
  const openPopup = (title, fields) => {
    setPopupTitle(title);
    setPopupFields(fields);
    setIsPopupVisible(true);
  };

  const handlePopupSave = (newFields) => {
    setProfileData({
      ...profileData,
      ...newFields,
    });
  };

  const handlePopupClose = () => {
    setIsPopupVisible(false);
  };
  return (
    <div className="contact-info">
      <div className="contact-option">
        <div className="contact-label">
          Contacts
          <FontAwesomeIcon
  icon={faEdit}
  className="edit-icon"
  onClick={() => {
    console.log('Edit icon clicked');
    openPopup("Edit Contacts", { email: profileData.email });
  }}
/>

        </div>
        <div className="contact-details dotted-box">
          <p>example@example.com</p>
        </div>
      </div>
      <div className="contact-option">
        <div className="contact-label">
          Personal Link
          <FontAwesomeIcon
            icon={faEdit}
            className="edit-icon"
            // onClick={() => handleEditClick('Personal Link', 'Add your custom URL')}
          />
        </div>
        <div className="contact-details dotted-box">
          <p>Add your custom URL</p>
          <a href="#" className="add-link">Add</a>
        </div>
      </div>
      <div className="contact-option">
        <div className="contact-label">
          Social Network
          <FontAwesomeIcon
            icon={faEdit}
            className="edit-icon"
            // onClick={() => handleEditClick('Social Network', 'Add links to your child\'s social accounts.')}
          />
        </div>
        <div className="contact-details dotted-box">
          <p>Add links to your child's social accounts.</p>
          <a href="#" className="add-link">Add</a>
        </div>
      </div>

      {isPopupVisible && (
  <EditPopup
    title={popupTitle}
    fields={popupFields}
    onSave={handlePopupSave}
    onClose={handlePopupClose}
  />
)}

    </div>
  );
};

export default ContactInfo;
